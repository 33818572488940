import getNotifier from '@/utils/getNotifier'
import { CHART_COUNT_STATUS } from '@/modules/dashboards/components/chart/options/constants'
import { getTimeZone } from '@/utils/onlineUtils'
import { camelize } from 'humps'

import {
  ACTION_AGENT_PAUSE,
  ACTION_AGENT_LOGOUT,
  ACTION_AGENT_PENALTY,
  ACTION_AGENT_UNPAUSE
} from './constants'
const getTokenJWT = window.getTokenJWT
const api = window.api

const notifier = getNotifier()
const CLEAR_ONLINE = 'ONLINE_CLEAR'
const CALL_REMOVE = 'ONLINE_CALL_REMOVE'
const CALL_UPDATE = 'ONLINE_CALL_UPDATE'
const AGENT_UPDATE = 'ONLINE_AGENT_UPDATE'
const RESET_ONLINE = 'ONLINE_RESET_ONLINE'
const QUEUE_UPDATE = 'QUEUE_UPDATE'
const QUEUE_PROPERTIES_UPDATE = 'QUEUE_PROPERTIES_UPDATE'

const SET_SUMMARIES = 'SET_SUMMARIES'
const EXTENSIONS_UPDATE = 'EXTENSIONS_UPDATE'
const REFRESH_SUMMARY_PARAMS = 'ONLINE_REFRESH_SUMMARY_PARAMS'

const MUTATION_AGENT_PERMISSIONS = 'MUTATION_AGENT_PERMISSIONS'
const MUTATION_AGENT_PERMISSIONS_SAVED = 'MUTATION_AGENT_PERMISSIONS_SAVED'
const MUTATION_AGENT_PERMISSIONS_LOADED = 'MUTATION_AGENT_PERMISSIONS_LOADED'
const isLogin = (event) => event === 'QUEUEMEMBER_LOGIN'
const isLogoff = (event) => event === 'QUEUEMEMBER_LOGOFF'

const getInitialState = () => ({
  calls: {},
  agents: {},
  queues: {},
  extensions: {},
  queueStatus: '',
  versionVP: '',

  summary: {
    summaries: [],
    loading: true,
    filter: {
      queues: [],
      periodSecs: 3600,
      periodStart: 0,
      periodEnd: 0,
      timezone: getTimeZone(),
      chartType: CHART_COUNT_STATUS
    }
  },

  listGraphs: {},
  loadingGraphs: true,

  permissions: {
    agents: {},
    loading: false
  }
})

export default {
  state: getInitialState(),

  actions: {
    startListenEventsPbx ({ commit }, params) {
      const onInitialState = (notification) => commit(RESET_ONLINE, sanitize(notification))
      const callRemove = (notification) => commit(CALL_REMOVE, sanitize(notification))
      const callUpdate = (notification) => commit(CALL_UPDATE, sanitize(notification))
      const agentUpdate = (notification) => commit(AGENT_UPDATE, sanitize(notification))
      const onChangeset = (notification) => commit(QUEUE_PROPERTIES_UPDATE, sanitize(notification))
      const extensionUpdate = (notification) => commit(EXTENSIONS_UPDATE, sanitize(notification))
      const onDialerStatus = (notification) => commit(QUEUE_UPDATE, sanitize(notification))

      const listenEvents = {
        onInitialState,
        onChangeset,
        onDialerStatus,

        onCallEnd: callRemove,
        onCallStart: callUpdate,
        onCallReject: callUpdate,
        onCallAttempt: callUpdate,
        onCallConnect: callUpdate,
        onRingCancel: callUpdate,

        onQueueJoin: callUpdate,
        onQueueAbandon: callRemove,

        onDialerStart: callUpdate,
        onDialerConnect: callUpdate,
        onDialerEnd: callRemove,
        extensionsUpdate: extensionUpdate,

        onAgentLogin: agentUpdate,
        onAgentPause: agentUpdate,
        onAgentLogoff: agentUpdate,
        onAgentUnpause: agentUpdate,
        onAgentPenalty: agentUpdate
      }

      listenEvents.onDisconnected = () => {
        commit(CLEAR_ONLINE)
        notifier.unregister()
        setTimeout(() => connectSocket(), 3000)
      }

      const connectSocket = () => {
        notifier.onEvents(getTokenJWT, { queues: params.queues }, listenEvents)
      }

      connectSocket()
    },

    stopListenEventsPbx ({ commit }) {
      notifier.close()
      commit(CLEAR_ONLINE)
    },

    refreshSummaryChart: ({ commit }, params) => {
      commit(REFRESH_SUMMARY_PARAMS, params)
    },

    [ACTION_AGENT_LOGOUT]: (_, agents) => {
      const logoutAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/logout`).catch(() => wait(500).then(() => logoutAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => logoutAgent(agentId, 7)))
    },

    [ACTION_AGENT_PAUSE]: (_, { agents, reason }) => {
      const pauseAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/pause`, { body: { reasonId: reason } }).catch(() => wait(500).then(() => pauseAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => pauseAgent(agentId, 7)))
    },

    [ACTION_AGENT_UNPAUSE]: (_, agents) => {
      const unpauseAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/unpause`).catch(() => wait(500).then(() => unpauseAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => unpauseAgent(agentId, 7)))
    },

    [ACTION_AGENT_PENALTY]: (_, { agents, queue, penalty }) => {
      const penaltyAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/penalty`, { body: { queueId: queue, penalty } }).catch(() => wait(500).then(() => penaltyAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => penaltyAgent(agentId, 7)))
    }
  },

  mutations: {
    [CLEAR_ONLINE]: (state) => {
      Object.assign(state, getInitialState())
    },

    [RESET_ONLINE]: (state, payload) => {
      const calls = { ...payload.calls }
      const agents = { ...payload.agents }
      const queues = { ...payload.queues }
      const extensions = { ...payload.extensions }
      const { versionVP } = payload
      const listcalls = Object.values(calls)

      Object.values(agents).forEach((agent) => {
        agent.calls = listcalls
          .filter(({ agentId }) => agentId === agent.id)
          .map(({ id }) => id)
      })

      Object.values(queues).forEach((queue) => {
        queue.calls = listcalls.filter(({ queueId }) => queueId === queue.id).map(({ id }) => id)
      })

      Object.assign(state, { calls, agents, queues, extensions, versionVP })
    },

    [SET_SUMMARIES]: (state, { summaries }) => {
      state.summary = {
        ...state.summary,
        summaries,
        loading: false
      }
    },

    [REFRESH_SUMMARY_PARAMS]: (state, params) => {
      const filter = state?.summary?.filter ? { ...state.summary.filter } : {}

      if (params?.queues) filter.queues = params.queues
      if (params?.timezone) filter.timezone = params.timezone
      if (params?.chartType) filter.chartType = params.chartType
      if (params?.periodEnd) filter.periodEnd = params.periodEnd
      if (params?.periodSecs) filter.periodSecs = params.periodSecs
      if (params?.periodStart) filter.periodStart = params.periodStart

      state.summary = { ...state.summary, filter, loading: true }
    },

    [AGENT_UPDATE]: (state, notification) => {
      const { queue: queueId, event: eventName } = notification.event
      const agentId = notification.event.agent
      const agent = getAgentUpdated(state, agentId, notification.agent)
      if (isLogoff(eventName)) delete agent.memberships[queueId]

      state.agents = { ...state.agents, [agentId]: agent }

      const tryAddAgentInQueue = isLogin(eventName)
      const tryRemoveAgentInQueue = isLogoff(eventName)
      if (!tryAddAgentInQueue && !tryRemoveAgentInQueue) return

      const queue = JSON.parse(JSON.stringify(state.queues[queueId] || {}))
      if (!queue.id) queue.id = queueId
      if (!queue.name) queue.name = queueId
      if (!queue.summary) queue.summary = {}
      if (!Array.isArray(queue.calls)) queue.calls = []
      if (!Array.isArray(queue.agents)) queue.agents = []

      const hasAgent = queue.agents.includes(agentId)
      if (tryAddAgentInQueue && !hasAgent) queue.agents.push(agentId)
      if (tryRemoveAgentInQueue && hasAgent) queue.agents.splice(queue.agents.indexOf(agentId), 1)

      state.queues = { ...state.queues, [queueId]: queue }
    },

    [CALL_REMOVE]: (state, notification) => {
      let { callId, queue: queueId, agent: agentId } = notification.event
      if (!agentId) agentId = notification?.agent?.id ?? null

      if (agentId) {
        const agent = getAgentUpdated(state, agentId, notification.agent)
        if (agent.calls.includes(callId)) agent.calls.splice(agent.calls.indexOf(callId), 1)
        state.agents = { ...state.agents, [agentId]: agent }
      }

      if (queueId) {
        const queue = getQueueUpdated(state, queueId, notification.queue)
        if (queue.calls.includes(callId)) queue.calls.splice(queue.calls.indexOf(callId), 1)
        state.queues = { ...state.queues, [queueId]: queue }
      }

      const calls = { ...state.calls }
      delete calls[callId]
      state.calls = calls
    },

    [CALL_UPDATE]: (state, notification) => {
      const { queue: queueId, callId, event } = notification.event
      const agentId = notification.event.agent ? notification.event.agent : null
      state.calls = Object.assign({}, state.calls, { [callId]: { ...notification.call } })

      if (queueId) {
        const queue = getQueueUpdated(state, queueId, notification.queue)
        if (!queue.calls.includes(callId)) queue.calls.push(callId)
        if (agentId && !queue.agents.includes(agentId)) queue.agents.push(agentId)
        state.queues = Object.assign({}, state.queues, { [queueId]: queue })
      }

      if (agentId) {
        const agent = getAgentUpdated(state, agentId, notification.agent)
        if (!agent.calls.includes(callId)) agent.calls.push(callId)
        state.agents = Object.assign({}, state.agents, { [agentId]: agent })

        const queueIsRingAll = state.queues[queueId]?.strategy === 'ringall'
        if (event === 'QUEUEMEMBER_CALLCONNECT' && queueIsRingAll) {
          for (const agentKey of Object.keys(state.agents)) {
            const isDifferentAgent = agentKey.toString() !== agentId.toString()
            const hasSameCall = state.agents[agentKey].talkingCallId === callId
            if (isDifferentAgent && hasSameCall) {
              state.agents[agentKey].calls = state.agents[agentKey].calls.filter((call) => call !== callId)
              if (state.agents[agentKey].calls.length === 0) {
                state.agents[agentKey].talkingCallId = ''
                state.agents[agentKey].talkingSince = null
                state.agents[agentKey].status = 'ONLINE'
                state.agents[agentKey].memberships[queueId] = 'ONLINE'
              }
            }
          }
        }

        if (['QUEUEMEMBER_CALLREJECT', 'QUEUEMEMBER_RINGCANCELED'].includes(event)) agent.calls = agent.calls.filter((call) => call !== callId)
      }
    },

    [QUEUE_UPDATE]: (state, notification) => {
      const queueId = notification?.event?.queue
      if (!queueId) return

      const { contacts, contactCalls, contactPrepareds, contactRequesteds, ...queueProps } = notification.event.state

      const queue = Object.assign({}, state.queues[queueId] || {})
      queue.id = queueId
      queue.contacts = Object.assign({}, contacts || {})
      queue.contactCalls = Object.assign({}, contactCalls || {})
      queue.contactPrepareds = Array.isArray(contactPrepareds) ? contactPrepareds : []
      queue.contactRequesteds = Array.isArray(contactRequesteds) ? contactRequesteds : []

      if (Object.keys(queueProps).length) Object.assign(queue, queueProps)
      state.queues = Object.assign({}, state.queues, { [queueId]: queue })
    },

    [EXTENSIONS_UPDATE]: (state, notification) => {
      const { extensions } = notification
      const { extensionsId, action } = notification.event

      if (action === 'RESET') state.extensions = {}

      for (const id of extensionsId) {
        state.extensions = { ...state.extensions, [id]: extensions[id] }
      }
    },

    [QUEUE_PROPERTIES_UPDATE]: (state, notification) => {
      const { queue, event } = notification
      if (!queue) return

      const queueId = event.queue || queue?.id
      if (!queueId) return

      const { dialerSpeed, lcrProfileId, dialerMaxTrunks, weight } = prepareProperties(queue.properties)
      state.queues[queueId] = { ...state.queues[queueId], dialerSpeed, lcrProfileId, dialerMaxTrunks, weight }
    },

    [MUTATION_AGENT_PERMISSIONS]: (state, clear = true) => {
      state.permissions = {
        salved: false,
        loading: true,
        agents: clear ? {} : { ...state.permissions.agents }
      }
    },

    [MUTATION_AGENT_PERMISSIONS_LOADED]: (state, agents) => {
      state.permissions = {
        salved: false,
        loading: false,
        agents: reducePerms(agents)
      }
    },

    [MUTATION_AGENT_PERMISSIONS_SAVED]: (state, agents) => {
      state.permissions = {
        salved: true,
        loading: false,
        agents: reducePerms(agents)
      }
    }
  },

  middlewares: {
    [REFRESH_SUMMARY_PARAMS]: ({ commit }, { payload }, { dashboardStore }) => {
      const body = { ...dashboardStore.summary.filter, ...payload }
      if (!body?.queues?.length) return

      const loadSummary = (times = 7) => {
        if (times <= 0) return null
        return api.post('/cc/summaries/reports', { body }).catch(() => wait(1000).then(() => loadSummary(--times)))
      }

      loadSummary().then(({ data: { rows } }) => {
        commit(SET_SUMMARIES, { summaries: rows })
      })
    }
  }
}

const getQueueUpdated = (state, queueId, data) => {
  const notification = { ...data }
  const queue = Object.assign({ id: queueId, name: queueId, agents: [], calls: [] }, state.queues[queueId])

  const calls = Array.isArray(data.calls || queue.calls) ? data.calls || queue.calls : []
  const agents = Array.isArray(data.agents || queue.agents) ? data.agents || queue.agents : []

  const dialerMaxTrunks = Number(data.dialerMaxTrunks ?? queue.dialerMaxTrunks)
  const dialerAvgDials = Number(data.dialerAvgDials ?? queue.dialerAvgDials)

  const dialerCountContacts = Number(data.dialerCountContacts ?? queue.dialerCountContacts)
  const dialerCountFiltered = Number(data.dialerCountFiltered ?? queue.dialerCountFiltered)

  const dialerCountFailure = Number(data.dialerCountFailure ?? queue.dialerCountFailure)
  const dialerCountAbandons = Number(data.dialerCountAbandons ?? queue.dialerCountAbandons)
  const dialerCountCompleted = Number(data.dialerCountCompleted ?? queue.dialerCountCompleted)

  const summary = mergeData(queue.summary, notification.summary)

  return Object.assign(queue, {
    ...notification,
    agents,
    calls,
    summary,
    dialerMaxTrunks,
    dialerAvgDials,

    dialerCountContacts,
    dialerCountFiltered,

    dialerCountFailure,
    dialerCountAbandons,
    dialerCountCompleted
  })
}

const getAgentUpdated = (state, agentId, agentNotification) => {
  const newAgentData = JSON.parse(JSON.stringify(agentNotification))
  const oldAgentData = state.agents[agentId] || {}
  const calls = Array.isArray(oldAgentData.calls) ? [...oldAgentData.calls] : []
  const memberships = mergeData(oldAgentData.memberships, newAgentData.memberships)
  const summaries = mergeData(oldAgentData.summaries, newAgentData.summaries)
  const summary = mergeData(oldAgentData.summary, summaries[''])
  return { ...oldAgentData, ...newAgentData, summary, summaries, calls, memberships }
}

const isObj = (val) => !isEmpty(val) && !Array.isArray(val) && typeof val === 'object'
const isEmpty = (obj) => [null, undefined, ''].includes(obj) || JSON.stringify(obj) === '{}'
const wait = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms))

const mergeData = (oldObj, newObj) => {
  if (isEmpty(newObj) && isEmpty(oldObj)) return {}
  if (isEmpty(newObj)) return oldObj
  if (isEmpty(oldObj)) return newObj

  const obj = { ...oldObj }

  for (const key in newObj) {
    if (isObj(newObj[key]) && !isObj(oldObj[key])) {
      obj[key] = newObj[key]
      continue
    }

    if (isObj(newObj[key]) && isObj(oldObj[key])) {
      obj[key] = mergeData(oldObj[key], newObj[key])
      continue
    }

    if (!isObj(newObj[key]) && !isEmpty(newObj[key])) {
      obj[key] = newObj[key]
      continue
    }
  }

  return obj
}

const defaultPerms = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false }

const reducePerms = (agents) => (
  agents.reduce((group, { id, dialPermissions }) => ({
    ...group,
    [id]: dialPermissions.reduce((groupPerm, { callTypeId }) => ({
      ...groupPerm, [callTypeId]: true
    }), defaultPerms)
  }), {})
)

const prepareProperties = (val) => {
  if (isEmpty(val)) return {}
  if (Array.isArray(val)) return val
  if (isObj(val)) return Object.keys(val).reduce((obj, key) => ({ ...obj, [camelize(key)]: String(val[key]) }), {})
  return val
}

const sanitize = (event) => {
  const hasAgent = event?.agent?.id
  const hasCallAgent = event?.call?.agentId
  const hasEventAgent = event?.event?.agent
  const hasSummaries = event?.agent?.summaries
  const hasMemberships = event?.agent?.memberships

  if (hasAgent) event.agent.id = String(event.agent.id).padStart(4, '0')
  if (hasCallAgent) event.call.agentId = String(event.call.agentId).padStart(4, '0')
  if (hasEventAgent) event.event.agent = String(event.event.agent).padStart(4, '0')

  if (hasMemberships) {
    Object.keys(event.agent.memberships).forEach((queueId) => {
      if (event.agent.memberships[queueId]?.agentId) {
        event.agent.memberships[queueId].agentId = String(event.agent.memberships[queueId].agentId).padStart(4, '0')
      }
    })
  }

  if (hasSummaries) {
    Object.keys(event.agent.summaries).forEach((queueId) => {
      if (event.agent.summaries[queueId]?.agentId) {
        event.agent.summaries[queueId].agentId = String(event.agent.summaries[queueId].agentId).padStart(4, '0')
      }
    })
  }

  return event
}

import Vue from 'vue'
import './styles/quasar.sass'
import lang from 'quasar/lang/pt-br'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';
import iconSet from 'quasar/icon-set/mdi-v4'
import '@quasar/extras/mdi-v4/mdi-v4.css'

Vue.use(Quasar, {
  lang,
  iconSet: iconSet,
  plugins: {
    Notify,
    Dialog,
    AppFullscreen
  }
})

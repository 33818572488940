import {
  ROUTE_QUICK_ANSWERS_LIST,
  ROUTE_QUICK_ANSWERS_EDIT,
  ROUTE_QUICK_ANSWERS_CREATE
} from './constants'

const QuickAnswerForm = () => import(/* webpackChunkName: "quick-answer-form" */ './views/QuickAnswerForm')
const QuickAnswerList = () => import(/* webpackChunkName: "quick-answer-list" */ './views/QuickAnswerList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Respostas Rápidas', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/respostas-rapidas',
  name: ROUTE_QUICK_ANSWERS_LIST,
  meta: { permission: 'adminBots', breadcrumb: baseBreadcrumbs(null, true) },
  component: QuickAnswerList
}, {
  path: '/v1/respostas-rapidas/adicionar',
  name: ROUTE_QUICK_ANSWERS_CREATE,
  meta: { permission: 'adminBots', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: QuickAnswerForm
}, {
  path: '/v1/respostas-rapidas/:id/editar',
  name: ROUTE_QUICK_ANSWERS_EDIT,
  meta: { permission: 'adminBots', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: QuickAnswerForm
}]

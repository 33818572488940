import {
  ROUTE_ENTRY_NUMBER_LIST,
  ROUTE_ENTRY_NUMBER_EDIT,
  ROUTE_ENTRY_NUMBER_CREATE
} from './constants'

const EntryNumberForm = () => import(/* webpackChunkName: "entry-numbers-form" */ './views/EntryNumberForm')
const EntryNumberList = () => import(/* webpackChunkName: "entry-numbers-list" */ './views/EntryNumberList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Números de Entrada', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/numeros-de-entrada',
  name: ROUTE_ENTRY_NUMBER_LIST,
  meta: { permission: 'adminEntryNumbers', breadcrumb: baseBreadcrumbs(null, true) },
  component: EntryNumberList
}, {
  path: '/v1/numeros-de-entrada/adicionar',
  name: ROUTE_ENTRY_NUMBER_CREATE,
  meta: { permission: 'adminEntryNumbers', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: EntryNumberForm
}, {
  path: '/v1/numeros-de-entrada/:id/editar',
  name: ROUTE_ENTRY_NUMBER_EDIT,
  meta: { permission: 'adminEntryNumbers', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: EntryNumberForm
}]

import { camelize } from 'humps'
const getAuthSession = window.getAuthSession
const api = window.api

const SET_RULES = 'SET_RULES'
const SET_METADATA = 'SET_METADATA'
const SET_DASHBOARD = 'SET_DASHBOARD'
const DELETE_DASHBOARD = 'DELETE_DASHBOARD'
const REQUEST_LOADING = 'REQUEST_LOADING'
const REQUEST_ERROR = 'REQUEST_ERROR'
const SET_USERS = 'SET_USERS'
const SET_SHARE_USERS = 'SET_SHARE_USERS'

export default {
  namespaced: true,

  state: {
    meta: {},
    error: null,
    sessionUserId: '',
    currentTab: '',
    allUsers: [],
    userDashboards: [],
    isLoading: false,
    isEditMode: false,
    showEdit: true,
    resultSet: {},
    queueId: null,

    columns: [],
    measures: [],
    dimensions: [],

    charts: {
      default: {
        loading: false,
        resultSet: {}
      }
    }
  },

  getters: {
    isLoading: (state) => state.isLoading,

    allUsers: (state) => {
      const { allUsers } = state
      return allUsers?.length ? allUsers.map((user) => ({ id: user.id, label: user.name })) : []
    },

    dashboards: (state) => {
      return state.userDashboards
    },

    measures: (state) => {
      if (!state?.meta?.measures) return []
      const measures = Array.isArray(state?.meta?.measures) ? state.meta.measures.map((measure) => Object.assign({ isMeasure: true }, measure)) : []
      return Object.values(measures.reduce(groupColumns, {}))
    },

    dimensions: (state) => {
      if (!state?.meta?.dimensions) return []
      const dimensions = Array.isArray(state?.meta?.dimensions) ? state.meta.dimensions.map((dimension) => Object.assign({ isDimension: true }, dimension)) : []
      return Object.values(dimensions.reduce(groupColumns, {}))
    },

    columnsTypeSecs: (state) => {
      if (!state?.meta?.dimensions) return []
      const { dimensions, measures } = state.meta
      return [...dimensions, ...measures].filter((col) => col.type === 'isSecs')
    },

    columns: (state) => {
      const measures = Array.isArray(state?.meta?.measures) ? state.meta.measures.map((measure) => Object.assign({ isMeasure: true }, measure)) : []
      const dimensions = Array.isArray(state?.meta?.dimensions) ? state.meta.dimensions.map((dimension) => Object.assign({ isDimension: true }, dimension)) : []
      return Object.values([].concat(dimensions, measures).reduce(groupColumns, {}))
    },

    mapColumns: (state) => {
      const measures = Array.isArray(state?.meta?.measures) ? state.meta.measures.map((measure) => Object.assign({ isMeasure: true }, measure)) : []
      const dimensions = Array.isArray(state?.meta?.dimensions) ? state.meta.dimensions.map((dimension) => Object.assign({ isDimension: true }, dimension)) : []

      return [].concat(dimensions, measures).reduce((acc, item) => {
        const column = toColumn(item)
        acc[column.id] = column
        return acc
      }, {})
    },

    labelColumns: (state) => {
      const measures = Array.isArray(state?.meta?.measures) ? state.meta.measures : []
      const dimensions = Array.isArray(state?.meta?.dimensions) ? state.meta.dimensions : []

      return [...dimensions, ...measures].reduce((acc, item) => {
        const column = toColumn(item)
        acc[column.field] = column.label
        return acc
      }, {})
    },

    dimensionsTime: (state) => {
      if (!state?.meta?.dimensions) return []
      const { dimensions } = state.meta
      const dimensionsTime = dimensions.filter(dim => ['isDate', 'isDateTime', 'isTime'].includes(dim.type))
      return Object.values(dimensionsTime.reduce(groupColumns, {}))
    },

    trees: (state) => {
      if (!state?.meta?.trees) return []
      const { trees } = state.meta
      return trees.children
    }
  },

  actions: {
    setDashRules ({ commit }, { id, rule }) {
      commit(SET_RULES, { id, rule })
    },

    async loadQueueMetadata ({ commit }, queueId) {
      if (!queueId) return
      commit(REQUEST_LOADING)

      try {
        const metadata = await api.get('/dashboards/meta', { queryStringParameters: { queueId } })
        commit(SET_METADATA, { metadata, queueId })
      } catch (err) {
        commit(REQUEST_ERROR, err)
      }
    },

    async loadMetadata ({ commit }) {
      commit(REQUEST_LOADING)

      try {
        const session = await getAuthSession()
        const metadata = await api.get('/dashboards/meta')
        commit(SET_METADATA, { metadata, session })
      } catch (err) {
        commit(REQUEST_ERROR, err)
      }
    },

    async loadAllUsers ({ commit, state }) {
      if (state.allUsers?.length) return

      commit(REQUEST_LOADING)

      try {
        const users = await api.get('/cc/users', { queryStringParameters: { limit: 1000 } })

        commit(SET_USERS, users)
      } catch (err) {
        commit(REQUEST_ERROR, err)
      }
    },

    setEditMode ({ commit }) {
      commit('setEditMode', true)
    },

    setNewDashboard ({ commit }, dashboard) {
      commit('setNewDashboard', dashboard)
    },

    showEditMode ({ commit }, show) {
      commit('showEditMode', show)
    },

    setCurrentTab ({ commit }, current) {
      commit('setCurrentTab', current)
    },

    cancelEditMode ({ commit }) {
      commit('setEditMode', false)
      commit('cancelDashboard')
    },

    async saveDashboards ({ commit }, { type: configType, config, isNew, id }) {
      commit(REQUEST_LOADING)

      try {
        const body = { type: configType, config }
        if (!isNew) body.id = id

        const dashboard = await api.post('/dashboards', { body }).then(({ data }) => data)
        commit(SET_DASHBOARD, dashboard)
      } catch (err) {
        commit(REQUEST_ERROR, err)
      }
    },

    async deleteDashboard ({ commit }, id) {
      commit(REQUEST_LOADING)
      try {
        const { dashboardId } = await api.del(`/dashboards/${id}`).then(({ data }) => data)

        commit(DELETE_DASHBOARD, dashboardId)
      } catch (err) {
        commit(REQUEST_ERROR, err)
      }
    },

    async setDashboardShare ({ commit }, { type, shareUsers, dashboardId }) {
      commit(REQUEST_LOADING)

      try {
        const body = { type, shareUsers }

        if (dashboardId) body.id = dashboardId
        const dashboard = await api.post('/dashboards/share', { body }).then(({ data }) => data)
        commit(SET_SHARE_USERS, dashboard)
      } catch (err) {
        commit(REQUEST_ERROR, err)
      }
    }
  },

  mutations: {
    [REQUEST_LOADING]: (state) => {
      state.error = null
      state.isLoading = true
    },

    [SET_RULES]: (state, { id, rule }) => {
      const dashboards = JSON.parse(JSON.stringify(state.meta.dashboards))
      const dashboard = dashboards.find((dash) => dash.id === id)
      if (!dashboard) return
      if (!dashboard.config) dashboard.config = {}
      dashboard.config.rule = Object.assign(dashboard.config.rule || {}, rule)
      state.meta = { ...state.meta, dashboards }
    },

    [SET_METADATA]: (state, data) => {
      state.isLoading = false
      state.resultSet = { rows: [], count: 0 }

      if (isNotEmpty(data.queueId)) state.queueId = data.queueId
      if (isNotEmpty(data.session)) state.sessionUserId = data.session['cognito:username']

      if (isNotEmpty(data.metadata)) {
        state.meta = data.metadata
        state.measures = transformData(data.metadata.measures)
        state.dimensions = transformData(data.metadata.dimensions)
        state.columns = [...state.measures, ...state.dimensions]
        state.userDashboards = getDashboards(data.metadata.dashboards, state)
      }
    },

    [SET_USERS]: (state, users) => {
      state.allUsers = users.data ?? []
      state.isLoading = false
    },

    [SET_SHARE_USERS]: (state, dashboard) => {
      const userDashboards = state.userDashboards.map(
        userDashboard =>
          userDashboard.id === dashboard.id
            ? { ...userDashboard, shareUsers: dashboard.shareUsers }
            : userDashboard)

      state.userDashboards = userDashboards
      state.isLoading = false
    },

    [SET_DASHBOARD]: (state, dashboard) => {
      state.isLoading = false
      state.isEditMode = false
      let dashboards = [...state.meta.dashboards]
      const mapIDs = dashboards.map(({ id }) => String(id))

      const index = mapIDs.indexOf(String(dashboard.id))
      index > -1 ? dashboards.splice(index, 1, dashboard) : dashboards.push(dashboard)
      dashboards = dashboards.filter(({ id }) => id)
      state.meta = { ...state.meta, dashboards }
      state.userDashboards = getDashboards(dashboards, state)
    },

    [DELETE_DASHBOARD]: (state, dashboardId) => {
      let { dashboards } = state.meta
      const userDashboards = state.userDashboards.filter(dashboard => dashboard.id !== Number(dashboardId))
      dashboards = dashboards.filter(dashboard => dashboard.id !== Number(dashboardId))

      state.meta = { ...state.meta, dashboards }
      state.userDashboards = userDashboards
      state.isLoading = false
    },

    [REQUEST_ERROR]: (state, error) => {
      state.isLoading = false
      state.error = error
    },

    setEditMode: (state, isEditMode) => {
      state.isEditMode = isEditMode
      if (!isEditMode) state.meta = { ...state.meta }
    },

    setNewDashboard: (state, newDash) => {
      state.userDashboards.unshift(newDash)
      state.currentTab = newDash.id
      state.isEditMode = true
      state.showEdit = true
    },

    cancelDashboard: (state) => {
      state.userDashboards = state.userDashboards.filter(dash => !dash.isNew)
    },

    showEditMode: (state, show) => {
      state.showEdit = show
    },

    setCurrentTab: (state, current) => {
      state.currentTab = current
    }
  }
}

const transformData = (meta) => (
  meta.reduce((cols, dim) => (
    cols.concat({
      id: dim.key,
      name: dim.key,
      label: dim.title || dim.key,
      field: camelize(dim.key.replace(/\./g, '_')),
      type: dim.type,
      member: dim.key,
      direction: 'asc'
    })
  ), [])
)

const newRootOption = (id, label) => ({
  id,
  label,
  children: []
})

const isNotEmpty = (val) => ![null, undefined, ''].includes(val)

const groupColumns = (acc, item) => {
  const keyId = item.id ?? item.key
  const schema = keyId.split('.').shift()
  const hasQueue = schema === 'profilers' && item.queueId
  if (!acc[schema]) acc[schema] = newRootOption(schema, schema)

  if (item.group || hasQueue) {
    const keyId = item.id ?? item.key
    const groupId = item.group?.concat(keyId)
    const group = item.group || item.queueId
    const fieldIsDeleted = item.title.split('.')[1] === 'deleted'
    item.title = item.title.split('.')[0]

    let indexGroup = acc[schema].children.findIndex((el) => el.label === group)

    if (indexGroup === -1) {
      indexGroup = acc[schema].children.length
      acc[schema].children.push(newRootOption(groupId ?? group, group))
    }

    if (fieldIsDeleted) {
      const findDeletedFields = acc[schema].children[indexGroup].children.find(({ id }) => id === `${group}-camposDeletados`)
      if (!findDeletedFields) acc[schema].children[indexGroup].children.push(newRootOption(`${group}-camposDeletados`, 'Campos deletados'))
    }

    const indexGroupDeletedField = acc[schema].children[indexGroup].children.findIndex((el) => el.id === `${group}-camposDeletados`)

    fieldIsDeleted
      ? acc[schema].children[indexGroup].children[indexGroupDeletedField].children.push(toColumn(item))
      : acc[schema].children[indexGroup].children.push(toColumn(item))
  } else {
    acc[schema].children.push(toColumn(item))
  }

  return acc
}

const toColumn = (col) => {
  const { key, title, type, schema } = col
  const label = title || key
  const field = col.field || camelize(col.key.replace(/\./g, '_'))
  const isMeasure = Boolean(col.isMeasure)
  const isDimension = Boolean(col.isDimension)
  return { id: key, label, field, type, name: key, schema, isMeasure, isDimension }
}

const getDashboards = (dashboards, state) => {
  const currentUserId = state.sessionUserId
  const userDashboard = dashboards.filter(({ userId, type }) => userId === currentUserId && type === 'attendances')
  const newDashboards = dashboards.filter(({ userId }) => userId !== currentUserId)
  const dash = clone(newDashboards)
  if (userDashboard.length) dash.unshift(...clone(userDashboard))
  return dash
}

const clone = (source) => JSON.parse(JSON.stringify(source))

export const etlLoadData = (queryStringParameters, onlyRows = true) => (
  api.get('/dashboards/data', { queryStringParameters })
    .then((result) => onlyRows ? result.rows : result)
    .catch(() => onlyRows ? [] : { rows: [], count: 0 })
)

import {
  ROUTE_URA_LIST,
  ROUTE_URA_EDIT,
  ROUTE_URA_CREATE,
  ROUTE_BANNER_LIST
} from './constants'

const UraForm = () => import(/* webpackChunkName: "uras-form" */ './views/UraForm')
const UraList = () => import(/* webpackChunkName: "uras-list" */ './views/UraList')
const BannerList = () => import(/* webpackChunkName: "banners-list" */ './views/BannerList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'URAs', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/uras',
  name: ROUTE_URA_LIST,
  meta: { permission: 'adminUras', breadcrumb: configBreadcrumbs(null, true) },
  component: UraList
}, {
  path: '/v1/uras/adicionar',
  name: ROUTE_URA_CREATE,
  meta: { permission: 'adminUras', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: UraForm
}, {
  path: '/v1/uras/:id/editar',
  name: ROUTE_URA_EDIT,
  meta: { permission: 'adminUras', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: UraForm
}, {
  path: '/v1/banners',
  name: ROUTE_BANNER_LIST,
  meta: { permission: 'adminBanners', breadcrumb: configBreadcrumbs({ label: 'Banners', active: true }) },
  component: BannerList
}]

const api = window.api

const EXTENSION_ERROR = 'EXTENSION_ERROR'
const EXTENSION_SUCCESS = 'EXTENSION_SUCCESS'
const EXTENSION_LOADING = 'EXTENSION_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  extensionsObj: (state) => state.rows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {})
}

const actions = {
  async loadExtensions ({ commit, getters }) {
    if (getters.isLoading) return
    commit(EXTENSION_LOADING)

    try {
      const result = await api.get('/extensions', { queryStringParameters: { limit: 9999 } })
      commit(EXTENSION_SUCCESS, result)
    } catch (err) {
      commit(EXTENSION_ERROR, err)
    }
  }
}

const mutations = {
  [EXTENSION_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [EXTENSION_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [EXTENSION_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'extensionStore/EXTENSION_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('extensionStore/loadExtensions', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

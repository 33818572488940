import {
  ROUTE_PLAN_LIST,
  ROUTE_PLAN_EDIT,
  ROUTE_PLAN_CREATE
} from './constants'

const PlanForm = () => import(/* webpackChunkName: "Plan-form" */ './views/PlanForm')
const PlanList = () => import(/* webpackChunkName: "Plan-list" */ './views/PlanList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Planos e Tarifas', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/planos',
  name: ROUTE_PLAN_LIST,
  meta: { permission: 'adminBillingCalls', breadcrumb: configBreadcrumbs(null, true) },
  component: PlanList
}, {
  path: '/v1/planos/adicionar',
  name: ROUTE_PLAN_CREATE,
  meta: { permission: 'adminBillingCalls', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: PlanForm
}, {
  path: '/v1/planos/:id/editar',
  name: ROUTE_PLAN_EDIT,
  meta: { permission: 'adminBillingCalls', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: PlanForm
}]

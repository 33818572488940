const api = window.api

const PROFILERS_ERROR = 'PROFILERS_ERROR'
const PROFILERS_SUCCESS = 'PROFILERS_SUCCESS'

const state = { rows: [] }

const getters = {
  profilers: (state) => state.rows
    .filter(profile => profile.tree)
    .map(({ id, description, tree }) => ({ id, label: description, tree })),
  profilerFields: (state) => state.rows
    .filter(profile => profile.fields.length)
    .map(({ id, description, fields }) => ({ id, label: description, fields }))
}

const actions = {
  async loadProfilers ({ commit }) {
    try {
      const result = await api.get('/profilers')
      commit(PROFILERS_SUCCESS, result)
    } catch (err) {
      commit(PROFILERS_ERROR, err)
    }
  }
}

const mutations = {
  [PROFILERS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, { rows })
  },

  [PROFILERS_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'profilersStore/PROFILERS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('profilersStore/loadProfilers', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

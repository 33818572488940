import {
  ROUTE_DENYLISTS_LIST,
  ROUTE_DENYLISTS_EDIT,
  ROUTE_DENYLISTS_CREATE
} from './constants'

const DenyForm = () => import(/* webpackChunkName: "deny-form" */ './views/DenyForm')
const DenyList = () => import(/* webpackChunkName: "deny-list" */ './views/DenyList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Denylists', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/denylists',
  name: ROUTE_DENYLISTS_LIST,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs(null, true) },
  component: DenyList
},
{
  path: '/v1/denylists/adicionar',
  name: ROUTE_DENYLISTS_CREATE,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs({ label: 'Adicionar' }) },
  component: DenyForm
}, {
  path: '/v1/denylists/:id/editar',
  name: ROUTE_DENYLISTS_EDIT,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs({ label: 'Editar #' }) },
  component: DenyForm
}]

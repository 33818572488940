export const START_CHAT = 'START_CHAT'
export const OFFERING_CHAT = 'OFFERING_CHAT'
export const REJECTOFFERING_CHAT = 'REJECTOFFERING_CHAT'
export const TIMEOUTOFFERING_CHAT = 'TIMEOUTOFFERING_CHAT'
export const ACCEPTEDOFFERING_CHAT = 'ACCEPTEDOFFERING_CHAT'
export const LOSEDOFFERING_CHAT = 'LOSEDOFFERING_CHAT'
export const TRANSFERED_CHAT = 'TRANSFERED_CHAT'
export const FINISHED_CHAT = 'FINISHED_CHAT'

export const ROUTE_CHAT_LIST = 'ROUTE_CHAT_LIST'
export const ROUTE_CHATSAGENTS = 'ROUTE_CHATSAGENTS'
export const ROUTE_CHATSBROKER = 'ROUTE_CHATSBROKER'
export const ROUTE_CHATSTEMPLATE = 'ROUTE_CHATSTEMPLATE'
export const ROUTE_CHATSQUEUE = 'ROUTE_CHATSQUEUE'

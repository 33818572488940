const api = window.api

const BOT_FLOW_ERROR = 'BOT_FLOW_ERROR'
const BOT_FLOW_SUCCESS = 'BOT_FLOW_SUCCESS'
const BOT_FLOW_LOADING = 'BOT_FLOW_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  flowObj: (state) => state.rows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {})
}

const actions = {
  async loadFlows ({ commit, getters }, args) {
    if (getters.isLoading) return

    commit(BOT_FLOW_LOADING, true)

    try {
      const result = await api.get('/cc/bot-flows', { queryStringParameters: { limit: 999, page: 1 } })
      commit(BOT_FLOW_SUCCESS, result)
    } catch (err) {
      commit(BOT_FLOW_ERROR, err)
    }
  }
}

const mutations = {
  [BOT_FLOW_LOADING] (state, loading) {
    Object.assign(state, { loading })
  },

  [BOT_FLOW_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [BOT_FLOW_ERROR] (state, err) {
    console.log(err)
    Object.assign(state, {
      rows: [],
      loaded: true,
      loading: false
    })
  }
}

const middlewares = {
  'botFlowStore/BOT_FLOW_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('botFlowStore/loadFlows', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

import {
  ROUTE_BOT_FLOW_LIST,
  ROUTE_BOT_FLOW_EDIT,
  ROUTE_BOT_FLOW_CREATE
} from './constants'

const BotFlowForm = () => import(/* webpackChunkName: "bot-flow-form" */ './views/BotFlowForm')
const BotFlowList = () => import(/* webpackChunkName: "bot-flow-list" */ './views/BotFlowList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Fluxos de Conversas', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/fluxos-conversas',
  name: ROUTE_BOT_FLOW_LIST,
  meta: { permission: 'adminBots', breadcrumb: configBreadcrumbs(null, true) },
  component: BotFlowList
}, {
  path: '/v1/fluxos-conversas/adicionar',
  name: ROUTE_BOT_FLOW_CREATE,
  meta: { permission: 'adminBots', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: BotFlowForm
}, {
  path: '/v1/fluxos-conversas/:id/editar',
  name: ROUTE_BOT_FLOW_EDIT,
  meta: { permission: 'adminBots', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: BotFlowForm
}]

const api = window.api

const LCR_PROFILE_ERROR = 'LCR_PROFILE_ERROR'
const LCR_PROFILE_SUCCESS = 'LCR_PROFILE_SUCCESS'
const LCR_PROFILE_LOADING = 'LCR_PROFILE_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading)
}

const actions = {
  async loadLcrProfiles ({ commit, getters }) {
    if (getters.isLoading) return
    commit(LCR_PROFILE_LOADING)

    try {
      const result = await api.get('/lcr-profiles')
      commit(LCR_PROFILE_SUCCESS, result)
    } catch (err) {
      commit(LCR_PROFILE_ERROR, err)
    }
  }
}

const mutations = {
  [LCR_PROFILE_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [LCR_PROFILE_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [LCR_PROFILE_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'lcrProfilesStore/LCR_PROFILE_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('lcrProfilesStore/loadLcrProfiles', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

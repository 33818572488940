export const byHour = 3600
export const byDay = byHour * 24
export const byMonth = byDay * 30
export const byYear = byDay * 365

export const CHART_COUNT_STATUS = 'CHART_COUNT_STATUS'
export const CHART_COUNT_COMPLETED_QUEUE = 'CHART_COUNT_COMPLETED_QUEUE'
export const CHART_COUNT_COMPLETED_DIRECTION = 'CHART_COUNT_COMPLETED_DIRECTION'

export const CHART_COUNT_DISCARDED_QUEUE = 'CHART_COUNT_DISCARDED_QUEUE'
export const CHART_COUNT_DISCARDED_DIRECTION = 'CHART_COUNT_DISCARDED_DIRECTION'

export const CHART_COUNT_ABANDONED_QUEUE = 'CHART_COUNT_ABANDONED_QUEUE'
export const CHART_COUNT_ABANDONED_DIRECTION = 'CHART_COUNT_ABANDONED_DIRECTION'

export const CHART_SECS_QUEUE_TMA = 'CHART_SECS_QUEUE_TMA'
export const CHART_SECS_DIRECTION_TMA = 'CHART_SECS_DIRECTION_TMA'

export const CHART_SECS_COMPLETED_QUEUE_TME = 'CHART_SECS_COMPLETED_QUEUE_TME'
export const CHART_SECS_COMPLETED_DIRECTION_TME = 'CHART_SECS_COMPLETED_DIRECTION_TME'

export const CHART_SECS_ABANDONED_QUEUE_TME = 'CHART_SECS_ABANDONED_QUEUE_TME'
export const CHART_SECS_ABANDONED_DIRECTION_TME = 'CHART_SECS_ABANDONED_DIRECTION_TME'

export const colors = [
  '#86CAFC',
  '#FFA300',
  '#0876D4',
  '#66D084',
  '#8A40C4',
  '#D32F2F',
  '#AB47BD',
  '#FF683A',
  '#AD1457',
  '#065AA1',
  '#9575CD',
  '#FBC02D',
  '#8D6E63',
  '#E57373',
  '#02BB78',
  '#FFCDD2',
  '#F44336',
  '#009D8A',
  '#FFCC78',
  '#C96124',
  '#7986CB',
  '#FF1744',
  '#00897B',
  '#00ACC1',
  '#D500F9',
  '#0277BD',
  '#558B2F',
  '#B388FF',
  '#80DEEA',
  '#006064'
]

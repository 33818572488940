import Vue from 'vue'
import VueRouter from 'vue-router'
import uraRoutes from '@/modules/uras/routes'
import botRoutes from '@/modules/bots/routes'
import tokenRoutes from '@/modules/tokens/routes'
import chatRoutes from '@/modules/chat/routes'
import emailRoutes from '@/modules/email/routes'
import authRoutes from '@/modules/auth/routes'
import financeRoutes from '@/modules/finance/routes'
import callRoutes from '@/modules/calls/routes'
import userRoutes from '@/modules/users/routes'
import queueRoutes from '@/modules/queues/routes'
import campaingsRoutes from '@/modules/campaings/routes'
import agentRoutes from '@/modules/agents/routes'
import pauseRoutes from '@/modules/pauses/routes'
import soundRoutes from '@/modules/sounds/routes'
import DenyListRoutes from '@/modules/denyLists/routes'
import profilerRoutes from '@/modules/profilers/routes'
import trunkingRoutes from '@/modules/trunkings/routes'
import transcriptionRoutes from '@/modules/transcriptionCalls/routes'
import planRoutes from '@/modules/plans/routes'
import extensionRoutes from '@/modules/extensions/routes'
import dashboardRoutes from '@/modules/dashboards/routes'
import lcrProfilesRoutes from '@/modules/lcrProfiles/routes'
import entryNumbersRoutes from '@/modules/entryNumbers/routes'
import quickAnswersRoutes from '@/modules/quickAnswers/routes'
import changelogRoutes from '@/modules/changelogs/routes'
import swaggerRoutes from '@/modules/swagger/routes'
import botFlowRoutes from '@/modules/botFlow/routes'
import { ROUTE_DASHBOARD_QUEUES } from '@/modules/dashboards/constants'
import { ROUTE_AUTH_SIGN_IN } from '@/modules/auth/constants'

Vue.use(VueRouter)

const panelRoutes = {
  path: '/v1',
  redirect: { name: ROUTE_DASHBOARD_QUEUES },
  component: () => import(/* webpackChunkName: "micro-layout" */ '@/modules/app/views/MicroLayout'),
  children: [
    ...uraRoutes,
    ...botRoutes,
    ...planRoutes,
    ...callRoutes,
    ...userRoutes,
    ...chatRoutes,
    ...emailRoutes,
    ...queueRoutes,
    ...financeRoutes,
    ...campaingsRoutes,
    ...agentRoutes,
    ...pauseRoutes,
    ...soundRoutes,
    ...profilerRoutes,
    ...trunkingRoutes,
    ...extensionRoutes,
    ...dashboardRoutes,
    ...lcrProfilesRoutes,
    ...entryNumbersRoutes,
    ...changelogRoutes,
    ...swaggerRoutes,
    ...quickAnswersRoutes,
    ...tokenRoutes,
    ...botFlowRoutes,
    ...DenyListRoutes,
    ...transcriptionRoutes,
    { path: '/v1/*', redirect: { name: ROUTE_DASHBOARD_QUEUES } }
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    authRoutes,
    panelRoutes,
    { path: '*', redirect: { name: ROUTE_AUTH_SIGN_IN } }
  ]
})

export default router

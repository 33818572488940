const api = window.api

const REQUEST_ERROR = 'REQUEST_ERROR'
const REQUEST_CLEAR = 'REQUEST_CLEAR'
const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
const REQUEST_LOADING = 'REQUEST_LOADING'

const actions = {
  async requestList ({ commit }, requestParams) {
    commit(REQUEST_LOADING)

    try {
      let endpoint, query
      const rowKey = requestParams.rowKey

      if (requestParams.etl) {
        query = { filter: JSON.stringify(requestParams.etl) }
        endpoint = '/dashboards/data'
      } else {
        query = requestParams.query
        endpoint = requestParams.endpoint
      }

      const result = await api.get(endpoint, { queryStringParameters: query })

      commit(REQUEST_SUCCESS, { rowKey, result, query: requestParams.etl ?? requestParams.query })
    } catch (err) {
      commit(REQUEST_ERROR, { ...requestParams, err })
    }
  },
  clear ({ commit }) {
    commit(REQUEST_CLEAR)
  }
}

const mutations = {
  [REQUEST_LOADING] (state) {
    Object.assign(state, {
      loading: true,
      canRetry: true
    })
  },

  [REQUEST_SUCCESS] (state, { rowKey, result, query }) {
    const dataRows = result.data ?? result.rows
    const rows = query.page === 1 ? dataRows : reduceRows(rowKey, state.rows, dataRows)
    const meta = result?.meta ?? {}
    const count = result?.meta?.count ?? result?.count ?? 0

    Object.assign(state, {
      rows,
      meta,
      query,
      count,
      error: null,
      loaded: true,
      loading: false,
      hasMore: rows.length < count
    })
  },

  [REQUEST_ERROR] (state, { err }) {
    console.log(err)

    Object.assign(state, {
      error: 'O sistema está tentando carregar as informações.',
      loading: false
    })
  },

  [REQUEST_CLEAR] (state) {
    Object.assign(state, resetState())
  }
}

const resetState = () => ({
  rows: [],
  count: 0,
  total: {},
  error: null,
  loaded: false,
  hasMore: false,
  loading: false,
  canRetry: false
})

const reduceRows = (rowKey, stateRows, resultRows) => {
  const stateIds = stateRows.reduce((grp, row) => grp.concat(row.id ?? row[rowKey] ?? row), [])
  return [...stateRows, ...resultRows.filter(({ id }) => !stateIds.includes(id))]
}

const middlewares = {
  'tableStore/REQUEST_ERROR': ({ dispatch }, { payload }, { canRetry }) => {
    if (!canRetry) return
    setTimeout(() => {
      if (!canRetry) return
      dispatch('tableStore/requestList', payload)
    }, 1500)
  }
}

export default {
  actions,
  mutations,
  middlewares,
  state: resetState(),
  namespaced: true
}

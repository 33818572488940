const api = window.api

const SOUND_ERROR = 'SOUND_ERROR'
const SOUND_SUCCESS = 'SOUND_SUCCESS'
const SOUND_LOADING = 'SOUND_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  sounds: (state) => state.rows.reduce((grouped, { id, sound }) => ({
    ...grouped,
    [id]: sound
  }), {})
}

const actions = {
  async loadSounds ({ commit, getters }) {
    if (getters.isLoading) return
    commit(SOUND_LOADING)

    try {
      const result = await api.get('/sounds', { queryStringParameters: { limit: 9999 } })
      commit(SOUND_SUCCESS, result)
    } catch (err) {
      commit(SOUND_ERROR, err)
    }
  }
}

const mutations = {
  [SOUND_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [SOUND_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [SOUND_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'soundStore/SOUND_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('soundStore/loadSounds', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}


import getNotifier from '@/utils/getNotifier'
const getTokenJWT = window.getTokenJWT

const CLEAR_STATE = 'CLEAR_STATE'
const UPDATE_CHAT = 'UPDATE_CHAT'
const REMOVE_CHAT = 'REMOVE_CHAT'
const UPDATE_AGENTS = 'UPDATE_AGENTS'
const RESET_ONLINE = 'RESET_ONLINE'
const TRANSFER_CHAT = 'TRANSFER_CHAT'
const notifier = getNotifier()
const statusEvent = {
  DISCONNECT: 'DISCONNECT',
  CONNECT: 'CONNECT',
  QUEUEMEMBER_PAUSE: 'PAUSED',
  QUEUEMEMBER_UNPAUSE: 'UNPAUSE'
}
const initialState = () => ({
  chats: {},
  agents: {},
  queues: {}
})

export default {
  state: initialState(),

  actions: {
    startListenEventsChat ({ commit }, params) {
      const onInitialState = (notification) => commit(RESET_ONLINE, notification)
      const agentUpdate = (notification) => commit(UPDATE_AGENTS, notification)
      const chatUpdate = (notification) => commit(UPDATE_CHAT, notification)
      const chatRemove = (notification) => commit(REMOVE_CHAT, notification)

      const listenEvents = {
        onInitialState,
        onAgentChatConnect: agentUpdate,
        onAgentChatDisconnect: agentUpdate,
        onAgentUnpause: agentUpdate,
        onAgentPause: agentUpdate,
        onPendingChat: chatUpdate,
        onFinish: chatRemove,
        onAccept: chatUpdate,
        onReject: chatRemove,
        onTransfer: chatUpdate,
        onOffer: chatUpdate
      }

      listenEvents.onDisconnected = () => {
        commit(CLEAR_STATE)
        notifier.close()
        setTimeout(() => connectSocket(), 3000)
      }

      const connectSocket = () => {
        notifier.onEvents(getTokenJWT, { queues: params.queues }, listenEvents)
      }

      connectSocket()
    },

    clearState ({ commit }) {
      commit(CLEAR_STATE)
    },

    onAgentChatConnect ({ commit }, params) {
      commit(UPDATE_AGENTS, params)
    },

    onAccept ({ commit }, params) {
      commit(UPDATE_CHAT, params)
    },

    onOfferChat ({ commit }, params) {
      commit(UPDATE_CHAT, params)
    },

    onGoingChat ({ commit }, params) {
      commit(UPDATE_CHAT, params)
    },

    onFinishedChat ({ commit }, params) {
      commit(REMOVE_CHAT, params)
    },

    onTransferChat ({ commit }, params) {
      commit(TRANSFER_CHAT, params)
    },

    onLosedChat ({ commit }, params) {
      commit(UPDATE_CHAT, params)
    },

    onPending ({ commit }, params) {
      commit(UPDATE_CHAT, params)
    }

  },

  mutations: {
    [CLEAR_STATE]: (state) => {
      Object.assign(state, initialState())
    },

    [RESET_ONLINE]: (state, payload) => {
      const chats = { ...payload.chats }
      const agents = { ...payload.agents }
      const queues = { ...payload.queues }
      const listchats = Object.values(chats)

      Object.keys(queues).forEach((queueId) => {
        if (!Array.isArray(queues[queueId].chats)) queues[queueId].chats = []
        queues[queueId].chats = queues[queueId].chats.filter((chatId, index) => queues[queueId].chats.indexOf(chatId) === index)
      })

      Object.values(agents).forEach((agent) => {
        agent.chats = listchats
          .filter(({ agentId }) => agentId === agent.id)
          .map(({ id }) => id)
      })

      Object.assign(state, { chats, agents, queues })
    },

    [UPDATE_AGENTS]: (state, notification) => {
      const { event } = notification
      const { agent: agentId, event: eventName } = event
      const agent = { ...state.agents[agentId], status: statusEvent[eventName].toLowerCase() }

      state.agents = { ...state.agents, [agentId]: agent }
    },

    [UPDATE_CHAT]: (state, notification) => {
      const { event } = notification
      const { agent, queue, chat, agentSummary, queueSummary } = event

      const chatsQueue = state.queues[queue]?.chats ?? []
      const summary = state.queues[queue]?.summary ?? {}
      const summaries = state.agents[agent]?.summaries ?? {}
      const chats = state.chats[chat.id] ?? []

      if (agent) {
        const chatsAgent = state.agents[agent]?.chats ?? []
        const agentsState = Object.keys(state.agents).reduce((acc, agentId) => {
          const chats = state.agents[agentId]?.chats ?? []
          acc[agentId] = { ...state.agents[agentId], chats: filterChats({ chats }, chat.id) }
          return acc
        }, {})

        state.agents = { ...agentsState, [agent]: { ...state.agents[agent], chats: [...new Set([...chatsAgent, String(chat.id)])], summaries: { ...summaries, ...agentSummary } } }

        if (chat.status === 'LOSED') {
          const chatsAgent = filterChats(state.agents[agent], chat.id)
          state.agents[agent] = { chats: chatsAgent }
          delete chat.agentId
        }
      }

      state.chats[chat.id] = { ...chats, ...makeChat(chat) }
      state.queues[queue] = { chats: [...new Set([...chatsQueue, String(chat.id)])], summary: { ...summary, ...queueSummary } }
    },

    [REMOVE_CHAT]: (state, notification) => {
      const { event, agent: agentData } = notification
      const { agent, queue, chat, agentSummary, queueSummary } = event

      if (agent) {
        const agentState = state.agents[agent] ?? { chats: [], ...agentData.agent }
        if (agentState?.chats.includes(chat.id)) agentState.chats.splice(agentState.chats.indexOf(chat.id), 1)
        state.agents = { ...state.agents, [agent]: { ...agentState, ...agentData.agent, summaries: { ...agentSummary } } }
      }

      if (queue) {
        const queueState = state.queues[queue] ?? { chats: [] }
        const chatIndex = queueState.chats.indexOf(String(chat.id))
        if (queueState?.chats.includes(String(chat.id))) queueState.chats.splice(chatIndex, 1)
        state.queues = { ...state.queues, [queue]: { ...queueState, summary: { ...queueSummary } } }
      }

      const chats = { ...state.chats }
      delete chats[chat.id]
      state.chats = chats
    }
  }
}

const filterChats = ({ chats }, chatId) => chats.filter((id) => String(id) !== String(chatId))
const makeChat = (chat) => ({
  name: chat.name,
  answeredAt: chat.answered_at,
  status: chat.status,
  source: chat.source,
  direction: chat.direction,
  queue: chat.queueId,
  createdAt: chat.created_at,
  agentId: chat.agentId
})

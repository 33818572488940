<template>
  <q-inner-loading
    v-if="isLoading"
    :showing="true"
  >
    <q-spinner-gears
      size="50px"
      color="primary"
    />
  </q-inner-loading>

  <router-view v-else />
</template>

<script>

export default {
  data () {
    const isLoading = true
    return { isLoading }
  },

  mounted () {
    const vm = this

    vm.timeout = setTimeout(() => {
      vm.isLoading = false
    }, 1500)

    window.broker.on('message', vm.onMessageBroker)
  },

  beforeDestroy () {
    const vm = this
    if (vm.timeout) clearTimeout(vm.timeout)
    window.broker.off('message', vm.onMessageBroker)
    vm.timeout = null
  },

  methods: {
    onMessageBroker (data) {
      const vm = this
      if (data?.event !== 'CLIENT_MENU_ROUTE') return
      if (data.payload.href) vm.$router.push(data.payload.href)
    }
  }
}
</script>

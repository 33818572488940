import {
  ROUTE_SOUND_LIST,
  ROUTE_SOUND_EDIT,
  ROUTE_SOUND_CREATE
} from './constants'

const SoundsForm = () => import(/* webpackChunkName: "sounds-form" */ './views/SoundsForm')
const SoundsList = () => import(/* webpackChunkName: "sounds-list" */ './views/SoundsList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Cadastro de Áudios', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/audios',
  name: ROUTE_SOUND_LIST,
  meta: { permission: 'adminSounds', breadcrumb: baseBreadcrumbs(null, true) },
  component: SoundsList
}, {
  path: '/v1/audios/adicionar',
  name: ROUTE_SOUND_CREATE,
  meta: { permission: 'adminSounds', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: SoundsForm
}, {
  path: '/v1/audios/:id/editar',
  name: ROUTE_SOUND_EDIT,
  meta: { permission: 'adminSounds', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: SoundsForm
}]

export default {
  actions: {
    ROUTE_CHANGED: (ctx, routeName) => {
      ctx.commit('onRouteChanged', routeName)
    }
  },

  mutations: {
    onRouteChanged: () => ({})
  }
}

import { ROUTE_DASHBOARD_QUEUES } from '@/modules/dashboards/constants'

import {
  ACTION_SIGN_IN,
  ACTION_SIGN_OUT,
  ACTION_AUTH_MFA,
  ACTION_CLEAR_STATE,
  ACTION_AUTH_FORGOT_PASSWORD,
  ACTION_AUTH_CHANGE_PASSWORD,
  ACTION_AUTH_DEFINE_PASSWORD,

  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_CHANGE,
  ROUTE_AUTH_PASSWORD_DEFINE
} from './constants'

const Auth = window.Auth
const getAuthSession = window.getAuthSession

const AUTH_ERROR = 'AUTH_ERROR'
const AUTH_LOADING = 'AUTH_LOADING'

const SIGN_OUT = 'SIGN_OUT'
const SIGN_MFA = 'SIGN_MFA'
const CLEAR_STATE = 'CLEAR_STATE'
const SET_SESSION = 'SET_SESSION'
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const PASSWORD_FORGOT_SUCCESS = 'PASSWORD_FORGOT_SUCCESS'
const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
const PASSWORD_DEFINE_SUCCESS = 'PASSWORD_DEFINE_SUCCESS'

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'

const isMFA = ({ challengeName }) => ['SMS_MFA', 'EMAIL_MFA'].includes(challengeName)
const forceNewPassword = ({ challengeName }) => challengeName === NEW_PASSWORD_REQUIRED

const resetState = () => ({
  session: null,
  loading: false,

  cognitoUser: null,
  cognitoInfo: null,
  cognitoError: null
})

export default (router) => ({
  state: resetState(),

  getters: {
    hasCognitoUser: (state) => {
      return Boolean(state.cognitoUser)
    },

    isAuthenticated: (state) => {
      return Boolean(state.session)
    }
  },

  actions: {
    [ACTION_SIGN_IN]: async ({ commit }, { email, password, redirect }) => {
      commit(AUTH_LOADING)

      try {
        const cognitoUser = await Auth.signIn(email, password)

        if (isMFA(cognitoUser)) return commit(SIGN_MFA, cognitoUser)
        if (forceNewPassword(cognitoUser)) return commit(NEW_PASSWORD_REQUIRED, cognitoUser)

        const session = await getAuthSession()
        commit(SIGN_IN_SUCCESS, { ...session, redirect })
      } catch (error) {
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_MFA]: async ({ commit, state }, code) => {
      commit(AUTH_LOADING)

      try {
        await Auth.confirmSignIn(state.cognitoUser, code)
        const session = await getAuthSession()
        commit(SIGN_IN_SUCCESS, session)
      } catch (error) {
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_FORGOT_PASSWORD]: async ({ commit }, email) => {
      commit(AUTH_LOADING)

      try {
        const cognitoUser = await Auth.forgotPassword(email)
        commit(PASSWORD_FORGOT_SUCCESS, { ...cognitoUser, email })
      } catch (error) {
        console.log(error)
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_CHANGE_PASSWORD]: async ({ dispatch, commit }, { email, code, password }) => {
      commit(AUTH_LOADING)

      try {
        await Auth.forgotPasswordSubmit(email, code, password)
        dispatch(ACTION_SIGN_IN, { email, password })
      } catch (error) {
        const retryCode = error.message.indexOf('please request a code again') > -1
        if (retryCode) return dispatch(ACTION_AUTH_FORGOT_PASSWORD, email)
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_DEFINE_PASSWORD]: async ({ dispatch, commit, state }, password) => {
      commit(AUTH_LOADING)

      try {
        const { email } = state.cognitoUser.challengeParam.userAttributes
        await Auth.completeNewPassword(state.cognitoUser, password)
        dispatch(ACTION_SIGN_IN, { email, password })
      } catch (error) {
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_SIGN_OUT]: async ({ commit }) => {
      await Auth.signOut().catch(() => ({}))
      commit(SIGN_OUT)
    },

    [ACTION_CLEAR_STATE]: async ({ commit }) => {
      await Auth.signOut().catch(() => ({}))
      commit(CLEAR_STATE)
    }
  },

  mutations: {
    [AUTH_LOADING]: (state) => {
      Object.assign(state, {
        loading: true,
        cognitoInfo: null,
        cognitoError: null
      })
    },

    [SIGN_IN_SUCCESS]: (state, session) => {
      Object.assign(state, {
        session,
        loading: false,
        cognitoUser: null
      })
    },

    [SET_SESSION]: (state, session) => {
      Object.assign(state, {
        session,
        loading: false,
        cognitoUser: null
      })
    },

    [PASSWORD_FORGOT_SUCCESS]: (state, cognitoUser) => {
      Object.assign(state, {
        loading: false,
        cognitoInfo: PASSWORD_FORGOT_SUCCESS,
        cognitoUser
      })
    },

    [SIGN_MFA]: (state, cognitoUser) => {
      Object.assign(state, {
        loading: false,
        cognitoUser,
        cognitoInfo: SIGN_MFA
      })
    },

    [NEW_PASSWORD_REQUIRED]: (state, cognitoUser) => {
      Object.assign(state, {
        loading: false,
        cognitoUser,
        cognitoInfo: NEW_PASSWORD_REQUIRED
      })
    },

    [PASSWORD_DEFINE_SUCCESS]: (state) => {
      Object.assign(state, {
        loading: false,
        cognitoUser: null,
        cognitoInfo: PASSWORD_DEFINE_SUCCESS
      })
    },

    [AUTH_ERROR]: (state, error) => {
      Object.assign(state, {
        loading: false,
        cognitoError: error.message
      })
    },

    [PASSWORD_CHANGE_SUCCESS]: (state) => {
      Object.assign(state, {
        loading: false,
        cognitoUser: null,
        cognitoInfo: PASSWORD_DEFINE_SUCCESS
      })
    },

    [SIGN_OUT]: (state) => {
      Object.assign(state, resetState())
    },

    [CLEAR_STATE]: (state) => {
      Object.assign(state, resetState())
    }
  },

  middlewares: {
    [SIGN_OUT]: () => router.push({ name: ROUTE_AUTH_SIGN_IN }).catch(() => ({})),
    [SIGN_MFA]: () => router.push({ name: ROUTE_AUTH_SIGN_MFA }).catch(() => ({})),
    [SIGN_IN_SUCCESS]: (_, { payload: { redirect } }) => router.push(redirect || { name: ROUTE_DASHBOARD_QUEUES }).catch(() => ({})),
    [NEW_PASSWORD_REQUIRED]: () => router.push({ name: ROUTE_AUTH_PASSWORD_DEFINE }).catch(() => ({})),
    [PASSWORD_FORGOT_SUCCESS]: (_, { payload: { email } }) => router.push({ name: ROUTE_AUTH_PASSWORD_CHANGE, query: { email } }).catch(() => ({})),
    [PASSWORD_DEFINE_SUCCESS]: (_, { payload: email }) => router.push({ name: ROUTE_AUTH_SIGN_IN, query: { email } }).catch(() => ({})),

    onRouteChanged: (ctx, { payload: routeName }) => routeName === ROUTE_AUTH_SIGN_IN && ctx.commit(CLEAR_STATE)
  }
})

const api = window.api

const FORM_CLEAR = 'FORM_CLEAR'

const FORM_LOADED = 'FORM_LOADED'
const FORM_LOADING = 'FORM_LOADING'
const FORM_LOAD_ERROR = 'FORM_LOAD_ERROR'
const SET_FORM_LOADING = 'SET_FORM_LOADING'

const FORM_SAVED = 'FORM_SAVED'
const FORM_SAVING = 'FORM_SAVING'
const FORM_SAVE_ERROR = 'FORM_SAVE_ERROR'

const actions = {
  async getApi ({ commit }, getUrl) {
    commit(FORM_LOADING)

    try {
      const result = getUrl ? await api.get(getUrl) : { data: {} }

      const data = result && result.data ? result.data : {}
      commit(FORM_LOADED, data)
    } catch (err) {
      commit(FORM_LOAD_ERROR, { getUrl, err })
    }
  },

  async sendApi ({ commit }, payload) {
    commit(FORM_SAVING)

    try {
      const { saveUrl, isEdit, body: { $state, ...body } } = payload
      const requestAction = isEdit ? 'put' : 'post'
      const data = await api[requestAction](saveUrl, { body })
      commit(FORM_SAVED, data || {})
    } catch (err) {
      commit(FORM_SAVE_ERROR, { ...payload, err: getError(err) })
    }
  },

  clear ({ commit }) {
    commit(FORM_CLEAR)
  },

  setSoundFile ({ commit }, payload) {
    commit('setSound', payload)
  },

  setFormSaving ({ commit }) {
    commit(FORM_SAVING)
  },

  setFormLoading ({ commit }, status) {
    commit(SET_FORM_LOADING, status)
  }
}

const getError = ({ response }) => {
  if (response.status === 400) return response.data
  return { $errors: { form: `Aconteceu um erro interno na nuvem: #${response.status}` } }
}

const mutations = {
  [FORM_LOADING] (state) {
    Object.assign(state, {
      isLoaded: false,
      isLoading: true,
      canRetry: true
    })
  },

  [SET_FORM_LOADING] (state, status) {
    Object.assign(state, {
      isLoading: status
    })
  },

  [FORM_LOADED] (state, data) {
    Object.assign(state, {
      data,
      isLoaded: true,
      isLoading: false
    })
  },

  [FORM_LOAD_ERROR] (state, { err }) {
    Object.assign(state, {
      errorLoad: 'O sistema está tentando carregar as informações.',
      isLoading: false
    })
  },

  [FORM_SAVING] (state) {
    Object.assign(state, {
      isSaved: false,
      isSaving: true,
      canRetry: false
    })
  },

  [FORM_SAVED] (state, data) {
    Object.assign(state, {
      data,
      isSaved: true,
      isSaving: false
    })
  },

  [FORM_SAVE_ERROR] (state, { err }) {
    Object.assign(state, {
      errorSave: err,
      isSaving: false
    })
  },

  [FORM_CLEAR] (state) {
    Object.assign(state, resetState())
  },

  'setSound' (state, data) {
    state.sound = data
  }
}

const resetState = () => ({
  data: null,
  error: null,
  sound: null,

  isSaved: false,
  isSaving: false,
  errorSave: null,

  isLoaded: false,
  isLoading: false,
  errorLoad: null,

  canRetry: false
})

const middlewares = {
  'formStore/FORM_LOAD_ERROR': ({ dispatch }, { payload }) => (
    setTimeout(() => (
      dispatch('formStore/getApi', payload.getUrl)
    ), 1500)
  )
}

export default {
  actions,
  mutations,
  middlewares,
  state: resetState(),
  namespaced: true
}

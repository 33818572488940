import {
  ROUTE_PROFILER_LIST,
  ROUTE_PROFILER_EDIT,
  ROUTE_PROFILER_CREATE
} from './constants'

const ProfilerForm = () => import(/* webpackChunkName: "profilers-form" */ './views/ProfilerForm')
const ProfilerList = () => import(/* webpackChunkName: "profilers-list" */ './views/ProfilerList')

const baseBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Perfiladores', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/perfiladores',
  name: ROUTE_PROFILER_LIST,
  meta: { permission: 'adminPerfilator', breadcrumb: baseBreadcrumbs(null, true) },
  component: ProfilerList
}, {
  path: '/v1/perfiladores/:queue/adicionar',
  name: ROUTE_PROFILER_CREATE,
  meta: { permission: 'adminPerfilator', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: ProfilerForm
}, {
  path: '/v1/perfiladores/:id/editar',
  name: ROUTE_PROFILER_EDIT,
  meta: { permission: 'adminPerfilator', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: ProfilerForm
}]

const api = window.api

const DENY_ERROR = 'DENY_ERROR'
const DENY_SUCCESS = 'DENY_SUCCESS'
const DENY_LOADING = 'DENY_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  denylistsObj: (state) => state.rows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {}),
  allDenylists: (state) => state.rows,
  denylistIds: (state) => state.rows.map(({ id }) => id)
}

const actions = {
  async loadDenylists ({ commit, getters }) {
    if (getters.isLoading) return
    commit(DENY_LOADING)

    try {
      const result = await api.get('/denylists', { queryStringParameters: { page: 1, limit: 50 } })
      commit(DENY_SUCCESS, result)
    } catch (err) {
      commit(DENY_ERROR, err)
    }
  },

  async searchDenylists ({ commit, getters }, phoneList) {
    if (getters.isLoading) return
    commit(DENY_LOADING)
    const phones = phoneList.join(',')
    try {
      const result = await api.get('/denylists', { queryStringParameters: { phone: phones } })
      commit(DENY_SUCCESS, result)
    } catch (err) {
      commit(DENY_ERROR, err)
    }
  },

  async patchDenylist (_, { id, phoneList, option }) {
    try {
      await api.patch(`/denylists/${id}`, { body: { phones: phoneList, option } })
    } catch (err) {
      console.log(err)
    }
  },

  reloadDenylists ({ dispatch }) {
    setTimeout(() => (
      dispatch('loadDenylists', { renew: true })
    ), 300)
  }
}

const mutations = {
  [DENY_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [DENY_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [DENY_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'denylistStore/DENY_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('denylistStore/loadDenylists', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

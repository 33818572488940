export const QUEUECALLER_JOIN = 'QUEUECALLER_JOIN'
export const QUEUEMEMBER_CALLCONNECT = 'QUEUEMEMBER_CALLCONNECT'
export const QUEUECALLER_ABANDON = 'QUEUECALLER_ABANDON'
export const QUEUEMEMBER_CALLEND = 'QUEUEMEMBER_CALLEND'
export const QUEUEMEMBER_CALLSTART = 'QUEUEMEMBER_CALLSTART'
export const QUEUEMEMBER_CALLREJECT = 'QUEUEMEMBER_CALLREJECT'
export const QUEUEMEMBER_CALLATTEMPT = 'QUEUEMEMBER_CALLATTEMPT'
export const QUEUEMEMBER_CALLDUMP = 'QUEUEMEMBER_CALLDUMP'
export const DIALER_CALLCONNECT = 'DIALER_CALLCONNECT'
export const DIALER_CALLSTART = 'DIALER_CALLSTART'
export const DIALER_CALLEND = 'DIALER_CALLEND'
export const QUEUEMEMBER_LOGIN = 'QUEUEMEMBER_LOGIN'
export const QUEUEMEMBER_LOGOFF = 'QUEUEMEMBER_LOGOFF'
export const QUEUEMEMBER_PAUSE = 'QUEUEMEMBER_PAUSE'
export const QUEUEMEMBER_UNPAUSE = 'QUEUEMEMBER_UNPAUSE'

export const colors = {
  QUEUEMEMBER_LOGIN: 'bg-blue-3',
  QUEUEMEMBER_LOGOFF: 'bg-blue-3',
  QUEUEMEMBER_PAUSE: 'bg-blue-3',
  QUEUEMEMBER_UNPAUSE: 'bg-blue-3',
  QUEUECALLER_JOIN: 'bg-green-3',
  QUEUEMEMBER_CALLCONNECT: 'bg-green-3',
  QUEUECALLER_ABANDON: 'bg-green-3',
  QUEUEMEMBER_CALLEND: 'bg-green-3',
  QUEUEMEMBER_CALLSTART: 'bg-green-3',
  QUEUEMEMBER_CALLREJECT: 'bg-red-3',
  QUEUEMEMBER_CALLATTEMPT: 'bg-green-3',
  QUEUEMEMBER_CALLDUMP: 'bg-red-3',
  DIALER_CALLCONNECT: 'bg-green-3',
  DIALER_CALLSTART: 'bg-green-3',
  DIALER_CALLEND: 'bg-green-3'
}

export const statusesColors = {
  DISCARDED: 'bg-red-3'
}

export const ROUTE_AGENT_LIST = 'ROUTE_LIST_AGENT'
export const ROUTE_AGENT_EDIT = 'ROUTE_EDIT_AGENT'
export const ROUTE_AGENTS_IDLE = 'ROUTE_AGENTS_IDLE'
export const ROUTE_AGENT_CREATE = 'ROUTE_AGENT_CREATE'
export const ROUTE_AGENTS_PAUSES = 'ROUTE_AGENTS_PAUSES'
export const ROUTE_AGENTS_RANKING = 'ROUTE_AGENTS_RANKING'
export const ROUTE_AGENTS_OVERVIEW = 'ROUTE_AGENTS_OVERVIEW'
export const ROUTE_AGENTS_SESSIONS = 'ROUTE_AGENTS_SESSIONS'
export const ROUTE_AGENTS_DETAILED = 'ROUTE_AGENTS_DETAILED'
export const ROUTE_AGENTS_FREQUENCY = 'ROUTE_AGENTS_FREQUENCY'
export const ROUTE_AGENTS_FREQUENCY_DATE = 'ROUTE_AGENTS_FREQUENCY_DATE'
export const ROUTE_AGENTS_SESSIONS_EVENTS = 'ROUTE_AGENTS_SESSIONS_EVENTS'
export const ROUTE_AGENTS_PRODUCTIVE = 'ROUTE_AGENTS_PRODUCTIVE'

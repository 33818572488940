import {
  ROUTE_TRUNKING_LIST,
  ROUTE_TRUNKING_EDIT,
  ROUTE_TRUNKING_CREATE
} from './constants'

const TrunkingForm = () => import(/* webpackChunkName: "trunking-form" */ './views/TrunkingForm')
const TrunkingList = () => import(/* webpackChunkName: "trunking-list" */ './views/TrunkingList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Troncos', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/troncos',
  name: ROUTE_TRUNKING_LIST,
  meta: { permission: 'adminTrunkings', breadcrumb: configBreadcrumbs(null, true) },
  component: TrunkingList
}, {
  path: '/v1/troncos/adicionar',
  name: ROUTE_TRUNKING_CREATE,
  meta: { permission: 'adminTrunkings', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: TrunkingForm
}, {
  path: '/v1/troncos/:id/editar',
  name: ROUTE_TRUNKING_EDIT,
  meta: { permission: 'adminTrunkings', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: TrunkingForm
}]

import {
  ROUTE_BOT_LIST,
  ROUTE_BOT_EDIT,
  ROUTE_BOT_CREATE
} from './constants'

const BotForm = () => import(/* webpackChunkName: "bots-form" */ './views/BotForm')
const BotList = () => import(/* webpackChunkName: "bots-list" */ './views/BotList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Brokers de Atendimento', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/brokers-de-atendimentos',
  name: ROUTE_BOT_LIST,
  meta: { permission: 'adminBots', breadcrumb: configBreadcrumbs(null, true) },
  component: BotList
}, {
  path: '/v1/brokers-de-atendimentos/adicionar',
  name: ROUTE_BOT_CREATE,
  meta: { permission: 'adminBots', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: BotForm
}, {
  path: '/v1/brokers-de-atendimentos/:id/editar',
  name: ROUTE_BOT_EDIT,
  meta: { permission: 'adminBots', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: BotForm
}]

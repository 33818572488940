const api = window.api

const URAS_ERROR = 'URAS_ERROR'
const URAS_SUCCESS = 'URAS_SUCCESS'
const URAS_LOADING = 'URAS_LOADING'
const TAGS_SUCCESS = 'TAGS_SUCCESS'

const state = {
  tags: [],
  rows: [],
  loading: false,
  token: ''
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  urasObj: (state) => state.rows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {}),
  urasSelect: (state) => state.rows.map((ura) => String(ura.id))
}

const actions = {
  async loadURAs ({ commit, getters }, args) {
    if (getters.isLoading) return

    commit(URAS_LOADING, true)

    try {
      const result = await api.get('/uras', { queryStringParameters: { limit: 999, page: 1 } })
      commit(URAS_SUCCESS, result)
    } catch (err) {
      commit(URAS_ERROR, err)
    }
  },

  async saveURA ({ commit }, { id, ura }) {
    commit(URAS_LOADING, true)

    try {
      id
        ? await api.put(`/uras/${id}`, { body: ura })
        : await api.post('/uras', { body: ura })

      commit(URAS_LOADING, false)
    } catch (err) {
      commit(URAS_ERROR, err)
    }
  },

  async loadConfig ({ commit }) {
    commit(URAS_LOADING, true)

    try {
      const token = await api.get('/uras/config/token')
      commit('setToken', token)
    } catch (err) {
      commit(URAS_ERROR, err)
    }
  },

  async loadTags ({ commit }, tag) {
    commit(URAS_LOADING, true)

    try {
      const result = await api.get('/uras/tags', { queryStringParameters: { description: tag } })
      commit(TAGS_SUCCESS, result)
    } catch (err) {
      commit(URAS_ERROR, err)
    }
  }
}

const mutations = {
  [URAS_LOADING] (state, loading) {
    Object.assign(state, { loading })
  },

  [URAS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [URAS_ERROR] (state, err) {
    console.log(err)
    Object.assign(state, {
      rows: [],
      loaded: true,
      loading: false
    })
  },

  [TAGS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      tags: rows,
      loading: false
    })
  },

  setToken (state, { data }) {
    state.token = data
    state.loading = false
  }
}

const middlewares = {
  'uraStore/URAS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('uraStore/loadURAs', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

import {
  ROUTE_EXTENSION_ADMIN_LIST,
  ROUTE_EXTENSION_ADMIN_CREATE,
  ROUTE_EXTENSION_ADMIN_EDIT,

  ROUTE_EXTENSION_CALLCENTER_LIST,
  ROUTE_EXTENSION_CALLCENTER_CREATE,
  ROUTE_EXTENSION_CALLCENTER_EDIT

} from './constants'

const ExtensionForm = () => import(/* webpackChunkName: "extensions-form" */ './views/ExtensionForm')
const ExtensionList = () => import(/* webpackChunkName: "extensions-list" */ './views/ExtensionList')

const baseBreadcrumbs = (child, parent) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: parent, active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })

  return breadcrumbs
}

const forceAdminAttr = { profile: 'ADMIN' }
const forceCallcenterAttr = { profile: 'CALLCENTER' }

export default [{
  path: '/v1/ramais-administrativos',
  name: ROUTE_EXTENSION_ADMIN_LIST,
  meta: { permission: 'adminExtensions', breadcrumb: baseBreadcrumbs(null, 'Ramais Administrativos'), forceAttr: forceAdminAttr },
  component: ExtensionList
}, {
  path: '/v1/ramais-administrativos/adicionar',
  name: ROUTE_EXTENSION_ADMIN_CREATE,
  meta: { permission: 'adminExtensions', breadcrumb: baseBreadcrumbs({ label: 'Adicionar' }, 'Ramais Administrativos'), forceAttr: forceAdminAttr },
  component: ExtensionForm
}, {
  path: '/v1/ramais-administrativos/:id/editar',
  name: ROUTE_EXTENSION_ADMIN_EDIT,
  meta: { permission: 'adminExtensions', breadcrumb: baseBreadcrumbs({ label: 'Editar #' }, 'Ramais Administrativos'), forceAttr: forceAdminAttr },
  component: ExtensionForm
}, {
  path: '/v1/ramais-callcenter',
  name: ROUTE_EXTENSION_CALLCENTER_LIST,
  meta: { permission: 'adminExtensions', breadcrumb: baseBreadcrumbs(null, 'Ramais de Callcenter'), forceAttr: forceCallcenterAttr },
  component: ExtensionList
}, {
  path: '/v1/ramais-callcenter/adicionar',
  name: ROUTE_EXTENSION_CALLCENTER_CREATE,
  meta: { permission: 'adminExtensions', breadcrumb: baseBreadcrumbs({ label: 'Adicionar' }, 'Ramais de Callcenter'), forceAttr: forceCallcenterAttr },
  component: ExtensionForm
}, {
  path: '/v1/ramais-callcenter/:id/editar',
  name: ROUTE_EXTENSION_CALLCENTER_EDIT,
  meta: { permission: 'adminExtensions', breadcrumb: baseBreadcrumbs({ label: 'Editar #' }, 'Ramais de Callcenter'), forceAttr: forceCallcenterAttr },
  component: ExtensionForm
}]

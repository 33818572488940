import { ROUTE_CHANGELOG_LIST } from './constants'

const ChangelogList = () => import(/* webpackChunkName: "agents-list" */ './views/ChangelogList')

export default [{
  path: '/v1/changelog',
  name: ROUTE_CHANGELOG_LIST,
  meta: { permission: 'adminAgents', breadcrumb: [{ label: 'Changelog', active: true }] },
  component: ChangelogList
}]

import {
  ROUTE_CHAT_LIST,
  ROUTE_CHATSAGENTS,
  ROUTE_CHATSBROKER,
  ROUTE_CHATSTEMPLATE,
  ROUTE_CHATSQUEUE
} from './constants'

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Conversas', active: false }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

const ChatList = () => import(/* webpackChunkName: "chats-list" */ './views/ChatList')
const ChatByAgents = () => import(/* webpackChunkName: "chat-by-agents" */ './views/ChatByAgents')
const ChatByBroker = () => import(/* webpackChunkName: "chat-by-broker" */ './views/ChatByBroker')
const ChatByTemplate = () => import(/* webpackChunkName: "chat-by-template" */ './views/ChatByTemplate')
const ChatByQueues = () => import(/* webpackChunkName: "chat-by-queues" */ './views/ChatByQueue')

export default [{
  path: '/v1/conversas',
  name: ROUTE_CHAT_LIST,
  meta: { tab: 'chats', breadcrumb: baseBreadcrumbs({ label: 'Busca Geral', active: true }) },
  component: ChatList
}, {
  path: '/v1/conversas/por-agentes',
  name: ROUTE_CHATSAGENTS,
  meta: { tab: 'chats', breadcrumb: baseBreadcrumbs({ label: 'Relatório de Chat por Agente', active: true }) },
  component: ChatByAgents
}, {
  path: '/v1/conversas/por-broker',
  name: ROUTE_CHATSBROKER,
  meta: { tab: 'chats', breadcrumb: baseBreadcrumbs({ label: 'Relatório de Chat por Broker', active: true }) },
  component: ChatByBroker
},
{
  path: '/v1/conversas/por-filas',
  name: ROUTE_CHATSQUEUE,
  meta: { tab: 'chats', breadcrumb: baseBreadcrumbs({ label: 'Relatório de Chat por Fila', active: true }) },
  component: ChatByQueues
},
{
  path: '/v1/conversas/por-template',
  name: ROUTE_CHATSTEMPLATE,
  meta: { tab: 'chats', breadcrumb: baseBreadcrumbs({ label: 'Relatório de Chats por Template', active: true }) },
  component: ChatByTemplate
}
]

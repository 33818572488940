import { etlLoadData } from '@/modules/attendances/attendanceStore'
import { getTimeZone } from '@/utils/onlineUtils'

const PERFORMANCE_SUCCESS = 'PERFORMANCE_SUCCESS'
const PERFORMANCE_LOADING = 'PERFORMANCE_LOADING'
const PERFORMANCE_ERROR = 'PERFORMANCE_ERROR'

const state = {
  loading: false,
  rowsIn: {},
  rowsOut: {},
  rowsAuto: {}
}

const actions = {
  async loadDataPerformance ({ commit }, params) {
    const { granularity, period, queues } = params

    columnQueuePeriod.granularity = granularity === '' ? 'BY_WEEK' : granularity
    columnAgentPeriod.granularity = granularity === '' ? 'BY_WEEK' : granularity

    const filterQueues = {
      page: 1,
      order: [{ ...columnQueuePeriod, direction: 'DESC' }],
      filters: [{ member: 'queues.id', condition: queues, type: 'isCategory' }],
      period: { value: period[0], rangeValue: period[1] },
      isChart: true
    }
    const filterAgents = {
      page: 1,
      order: [{ ...columnAgentPeriod, direction: 'DESC' }],
      columns: [columnAgentPeriod, ...colAgents],
      filters: [{ member: 'queues.id', condition: queues, type: 'isCategory' }],
      period: { value: period[0], rangeValue: period[1] },
      isChart: true
    }

    try {
      commit(PERFORMANCE_LOADING)
      const queueSummaryIn = await etlLoadData({ filter: JSON.stringify({ ...filterQueues, columns: [columnQueuePeriod, ...colIn] }) })
      const queueSummaryOut = await etlLoadData({ filter: JSON.stringify({ ...filterQueues, columns: [columnQueuePeriod, ...colOut] }) })
      const queueSummaryAuto = await etlLoadData({ filter: JSON.stringify({ ...filterQueues, columns: [columnQueuePeriod, ...colAuto] }) })
      const agentSummary = await etlLoadData({ filter: JSON.stringify(filterAgents) })
      commit(PERFORMANCE_SUCCESS, { queueSummaryIn, queueSummaryOut, queueSummaryAuto, agentSummary })
    } catch (err) {
      commit(PERFORMANCE_ERROR, err)
    }
  }
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  rowsInPerformance: ({ rowsIn }) => rowsIn,
  rowsOutPerformance: ({ rowsOut }) => rowsOut,
  rowsAutoPerformance: ({ rowsAuto }) => rowsAuto
}

const mutations = {
  [PERFORMANCE_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [PERFORMANCE_SUCCESS] (state, rows) {
    const { queueSummaryIn, queueSummaryOut, queueSummaryAuto, agentSummary } = rows

    Object.assign(state, {
      loading: false,
      rowsIn: mergeSummary(queueSummaryIn, agentSummary),
      rowsOut: mergeSummary(queueSummaryOut, agentSummary),
      rowsAuto: mergeSummary(queueSummaryAuto, agentSummary)
    })
  },

  [PERFORMANCE_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'callsStore/PERFORMANCE_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('callsStore/loadDataPerformance', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares
}

const mergeSummary = (queueSummary, agentSummary) => (
  queueSummary.map(queue => {
    agentSummary.map(agent => {
      if (queue.queueSummaryPeriod === agent.agentSummaryPeriod) {
        queue.countAgents = agent.agentSummaryCountAgents
      }
      return agent
    })
    return queue
  })
)

const columnQueuePeriod = { member: 'queueSummary.period', timezone: getTimeZone() }
const columnAgentPeriod = { member: 'agentSummary.period', timezone: getTimeZone() }
const colAgents = ['agentSummary.countAgents']
const colOut = [
  'queueSummary.totalO',
  'queueSummary.outCompleted',
  'queueSummary.outTransferred',
  'queueSummary.outDiscarded',
  'queueSummary.tmaO',
  'queueSummary.loginSecs'
]
const colAuto = [
  'queueSummary.totalA',
  'queueSummary.autoCompleted',
  'queueSummary.autoTransferred',
  'queueSummary.autoDiscarded',
  'queueSummary.autoAbandoned',
  'queueSummary.autoTmeC',
  'queueSummary.autoTmeA',
  'queueSummary.tmaA'
]
const colIn = [
  'queueSummary.inCompleted',
  'queueSummary.inTransferred',
  'queueSummary.totalR',
  'queueSummary.tmeC',
  'queueSummary.tmeA',
  'queueSummary.tmaR',

  'queueSummary.sla',
  'queueSummary.inCompletedSla',
  'queueSummary.inCompletedOutSla',
  'queueSummary.inAbandoned'
]

import {
  ROUTE_CAMPAINGS_LIST,
  ROUTE_CAMPAINGS_EDIT,
  ROUTE_CAMPAINGS_CREATE
} from './constants'

const CampaingsForm = () => import(/* webpackChunkName: "queues-form" */ './views/CampaingsForm')
const CampaingsList = () => import(/* webpackChunkName: "queues-list" */ './views/CampaingsList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Filas de Fonação', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/filas-de-fonacao',
  name: ROUTE_CAMPAINGS_LIST,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs(null, true) },
  component: CampaingsList
}, {
  path: '/v1/filas-de-fonacao/adicionar',
  name: ROUTE_CAMPAINGS_CREATE,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: CampaingsForm
}, {
  path: '/v1/filas-de-fonacao/:id/editar',
  name: ROUTE_CAMPAINGS_EDIT,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: CampaingsForm
}]

import {
  ROUTE_TRANSCRIPTION_LIST,
  ROUTE_TRANSCRIPTION_EDIT,
  ROUTE_TRANSCRIPTION_CREATE
} from './constants'

const TranscriptionForm = () =>
  import(/* webpackChunkName: "transcription-form" */ './views/TranscriptionForm')
const TranscriptionList = () =>
  import(/* webpackChunkName: "transcription-list" */ './views/TranscriptionList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Transcrição de Chamadas', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [
  {
    path: '/v1/transcricoes-de-chamadas',
    name: ROUTE_TRANSCRIPTION_LIST,
    meta: {
      permission: 'adminTranscriptions',
      breadcrumb: configBreadcrumbs(null, true)
    },
    component: TranscriptionList
  },
  {
    path: '/v1/transcricoes-de-chamadas/adicionar',
    name: ROUTE_TRANSCRIPTION_CREATE,
    meta: {
      permission: 'adminTranscriptions',
      breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true })
    },
    component: TranscriptionForm
  },
  {
    path: '/v1/transcricoes-de-chamadas/:id/editar',
    name: ROUTE_TRANSCRIPTION_EDIT,
    meta: {
      permission: 'adminTranscriptions',
      breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true })
    },
    component: TranscriptionForm
  }
]

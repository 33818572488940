import {
  ROUTE_BLOCKED_ACCESS,
  ROUTE_DASHBOARD_AGENTS,
  ROUTE_DASHBOARD_QUEUES,
  ROUTE_DASHBOARD_GENERAL,
  ROUTE_DASHBOARD_ATTENDANCE,

  ROUTE_DASHBOARD_MAILING,
  ROUTE_DASHBOARD_MAILING_VIEW,
  ROUTE_DASHBOARD_MAILING_IMPORT,
  ROUTE_DASHBOARD_MAILING_CONTACTS
} from './constants'

const baseBreadcrumbs = (children) => {
  const breadcrumbs = [{ label: 'Dashboards', active: false }]

  if (Array.isArray(children)) {
    breadcrumbs.push(...children)
  } else {
    breadcrumbs.push(children)
  }

  return breadcrumbs
}

export default [{
  path: '/v1/dashboards/geral',
  name: ROUTE_DASHBOARD_GENERAL,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Geral', active: true }) },
  component: () => import(/* webpackChunkName: "dashboards-queues" */ './views/DashboardGeneral')
}, {
  path: '/v1/dashboards/queues',
  name: ROUTE_DASHBOARD_QUEUES,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Filas', active: true }) },
  component: () => import(/* webpackChunkName: "dashboards-queues" */ './views/DashboardQueues')
}, {
  path: '/v1/dashboards/agents',
  name: ROUTE_DASHBOARD_AGENTS,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Agentes', active: true }) },
  component: () => import(/* webpackChunkName: "dashboards-agents" */ './views/DashboardAgents')
}, {
  path: '/v1/dashboards/relatorios',
  name: ROUTE_DASHBOARD_ATTENDANCE,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Relatórios', active: true }) },
  component: () => import(/* webpackChunkName: "dashboards-attendances" */ '@/modules/attendances/views/DashboardAttendance')
}, {
  path: '/v1/dashboards/discadores',
  name: ROUTE_DASHBOARD_MAILING,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Discador', active: true }) },
  component: () => import(/* webpackChunkName: "campaing-layout" */ './views/CampaingLayout')
}, {
  path: '/v1/dashboards/:queueId/acompanhar',
  name: ROUTE_DASHBOARD_MAILING_VIEW,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Discador', active: true }) },
  component: () => import(/* webpackChunkName: "campaing-layout" */ './views/CampaingLayout')
}, {
  path: '/v1/dashboards/:queueId/contatos',
  name: ROUTE_DASHBOARD_MAILING_CONTACTS,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Contatos', active: true }) },
  component: () => import(/* webpackChunkName: "campaing-layout" */ './views/CampaingLayout')
}, {
  path: '/v1/dashboards/:queueId/importar',
  name: ROUTE_DASHBOARD_MAILING_IMPORT,
  meta: { tab: 'dashboards', breadcrumb: baseBreadcrumbs({ label: 'Importar', active: true }) },
  component: () => import(/* webpackChunkName: "campaing-layout" */ './views/CampaingLayout')
}, {
  path: '/v1/acesso-bloqueado',
  name: ROUTE_BLOCKED_ACCESS,
  component: () => import(/* webpackChunkName: "accessBlocked-page" */ './views/AccessBlockedPage')
}]

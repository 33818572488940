export default (modules) => {
  const middlewares = reduceMiddlewares(modules)

  return (store) => (
    store.subscribe((mutation, state) => {
      if (hasProperty(middlewares, mutation.type)) {
        middlewares[mutation.type].forEach((middleware) => (
          middleware(store, mutation, state)
        ))
      }
    })
  )
}

const hasProperty = (object, property) => Object.prototype.hasOwnProperty.call(object, property)

const reduceMiddlewares = (modules) => (
  Object.keys(modules).reduce((all, moduleName) => {
    const { middlewares } = modules[moduleName]
    if (!middlewares) return all

    return Object.keys(middlewares).reduce((mutations, mutationName) => {
      const handlers = Array.isArray(all[mutationName]) ? all[mutationName] : []
      return { ...mutations, [mutationName]: [...handlers, middlewares[mutationName]] }
    }, all)
  }, {})
)

import {
  ROUTE_USER_LIST,
  ROUTE_USER_EDIT,
  ROUTE_USER_CREATE
} from './constants'

const baseBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Usuários', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

const UserForm = () => import(/* webpackChunkName: "users-form" */ './views/UserForm')
const UserList = () => import(/* webpackChunkName: "users-list" */ './views/UserList')

export default [{
  path: '/v1/usuarios',
  name: ROUTE_USER_LIST,
  meta: { permission: 'adminUsers', breadcrumb: baseBreadcrumbs(null, true) },
  component: UserList
}, {
  path: '/v1/usuarios/adicionar',
  name: ROUTE_USER_CREATE,
  meta: { permission: 'adminUsers', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: UserForm
}, {
  path: '/v1/usuarios/:id/editar',
  name: ROUTE_USER_EDIT,
  meta: { permission: 'adminUsers', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: UserForm
}]

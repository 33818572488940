const api = window.api

const PAUSE_REASONS_ERROR = 'PAUSE_REASONS_ERROR'
const PAUSE_REASONS_SUCCESS = 'PAUSE_REASONS_SUCCESS'
const PAUSE_REASONS_LOADING = 'PAUSE_REASONS_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading)
}

const actions = {
  async loadPauseReasons ({ commit, getters }) {
    if (getters.isLoading) return
    commit(PAUSE_REASONS_LOADING)

    try {
      const result = await api.get('/pause-reasons')
      commit(PAUSE_REASONS_SUCCESS, result)
    } catch (err) {
      commit(PAUSE_REASONS_ERROR, err)
    }
  }
}

const mutations = {
  [PAUSE_REASONS_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [PAUSE_REASONS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [PAUSE_REASONS_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'pauseReasonsStore/PAUSE_REASONS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('pauseReasonsStore/loadPauseReasons', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

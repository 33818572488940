const api = window.api

const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR'
const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS'
const PERMISSIONS_LOADING = 'PERMISSIONS_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading)
}

const actions = {
  async loadDialPermissions ({ commit, getters }) {
    if (getters.isLoading) return
    commit(PERMISSIONS_LOADING)

    try {
      const result = await api.get('/call-types')
      commit(PERMISSIONS_SUCCESS, result)
    } catch (err) {
      commit(PERMISSIONS_ERROR, err)
    }
  }
}

const mutations = {
  [PERMISSIONS_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [PERMISSIONS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [PERMISSIONS_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'dialPermissionsStore/PERMISSIONS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('dialPermissionsStore/loadDialPermissions', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

import {
  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_FORGOT,
  ROUTE_AUTH_PASSWORD_DEFINE,
  ROUTE_AUTH_PASSWORD_CHANGE
} from './constants'

const AuthLayout = import(/* webpackChunkName: "auth-layout" */ '@/modules/auth/components/AuthLayout.vue')
const AuthSignIn = import(/* webpackChunkName: "auth-signin" */ '@/modules/auth/views/AuthSignIn.vue')
const AuthSignMFA = import(/* webpackChunkName: "auth-mfa" */ '@/modules/auth/views/AuthSignMFA.vue')
const AuthForgot = import(/* webpackChunkName: "auth-forgot" */ '@/modules/auth/views/AuthForgot.vue')
const AuthDefinePassword = import(/* webpackChunkName: "auth-define-password" */ '@/modules/auth/views/AuthDefinePassword.vue')
const AuthChangePassword = import(/* webpackChunkName: "auth-change-password" */ '@/modules/auth/views/AuthChangePassword.vue')

export default {
  path: '/acesso',
  redirect: { name: ROUTE_AUTH_SIGN_IN },
  component: () => AuthLayout,
  children: [{
    path: '/acesso/entrar',
    name: ROUTE_AUTH_SIGN_IN,
    meta: { step: 'Entre com suas credenciais' },
    component: () => AuthSignIn
  }, {
    path: '/acesso/confirmar-codigo',
    name: ROUTE_AUTH_SIGN_MFA,
    meta: { step: 'Confirmar código de Autenticação' },
    component: () => AuthSignMFA
  }, {
    path: '/acesso/recuperar-senha',
    name: ROUTE_AUTH_PASSWORD_FORGOT,
    meta: { step: 'Esqueceu a senha?' },
    component: () => AuthForgot
  }, {
    path: '/acesso/definir-senha',
    name: ROUTE_AUTH_PASSWORD_DEFINE,
    meta: { step: 'Definir senha' },
    component: () => AuthDefinePassword
  }, {
    path: '/acesso/alterar-senha',
    name: ROUTE_AUTH_PASSWORD_CHANGE,
    meta: { step: 'Alterar senha' },
    component: () => AuthChangePassword
  }]
}

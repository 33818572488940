import {
  ROUTE_AGENT_LIST,
  ROUTE_AGENT_EDIT,
  ROUTE_AGENTS_IDLE,
  ROUTE_AGENT_CREATE,
  ROUTE_AGENTS_PAUSES,
  ROUTE_AGENTS_RANKING,
  ROUTE_AGENTS_SESSIONS,
  ROUTE_AGENTS_SESSIONS_EVENTS,
  ROUTE_AGENTS_OVERVIEW,
  ROUTE_AGENTS_FREQUENCY,
  ROUTE_AGENTS_FREQUENCY_DATE,
  ROUTE_AGENTS_DETAILED,
  ROUTE_AGENTS_PRODUCTIVE
} from './constants'

const AgentForm = () => import(/* webpackChunkName: "agents-form" */ './views/AgentForm')
const AgentList = () => import(/* webpackChunkName: "agents-list" */ './views/AgentList')
const AgentsPausesReport = () => import(/* webpackChunkName: "agents-pauses" */ './views/AgentsPauses')
const AgentsRankingReport = () => import(/* webpackChunkName: "agents-ranking" */ './views/AgentsRanking')
const AgentsIdleReport = () => import(/* webpackChunkName: "agents-sessions" */ './views/AgentsIdle')
const AgentsDetailedReport = () => import(/* webpackChunkName: "agents-ranking" */ './views/AgentsDetailed')
const AgentsOverviewReport = () => import(/* webpackChunkName: "agents-overview" */ './views/AgentsOverview')
const AgentsSessionsReport = () => import(/* webpackChunkName: "agents-sessions" */ './views/AgentsSessions')
const AgentsSessionsEventsReport = () => import(/* webpackChunkName: "agents-sessions-events" */ './views/AgentsSessionEvents')
const AgentsFrequencyReport = () => import(/* webpackChunkName: "agents-frequency" */ './views/AgentsFrequency')
const AgentsFrequencyDateReport = () => import(/* webpackChunkName: "agents-frequency-date" */ './views/AgentsFrequencyDate')
const AgentsProductive = () => import(/* webpackChunkName: "agents-productive" */ './views/AgentsProductive')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Agentes', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

const reportBreadcrumbs = (label) => ([
  { label: 'Consulta de Agentes', active: false },
  { label, active: true }
])

export default [{
  path: '/v1/agentes',
  name: ROUTE_AGENT_LIST,
  meta: { permission: 'adminAgents', breadcrumb: configBreadcrumbs(null, true) },
  component: AgentList
}, {
  path: '/v1/agentes/adicionar',
  name: ROUTE_AGENT_CREATE,
  meta: { permission: 'adminAgents', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: AgentForm
}, {
  path: '/v1/agentes/:id/editar',
  name: ROUTE_AGENT_EDIT,
  meta: { permission: 'adminAgents', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: AgentForm
}, {
  path: '/v1/agentes/visao-geral',
  name: ROUTE_AGENTS_OVERVIEW,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Visão Geral') },
  component: AgentsOverviewReport
}, {
  path: '/v1/agentes/ranking',
  name: ROUTE_AGENTS_RANKING,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Ranking') },
  component: AgentsRankingReport
}, {
  path: '/v1/agentes/pausas',
  name: ROUTE_AGENTS_PAUSES,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Pausas') },
  component: AgentsPausesReport
}, {
  path: '/v1/agentes/frequencia-do-agente',
  name: ROUTE_AGENTS_FREQUENCY,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Frequência do Agente') },
  component: AgentsFrequencyReport
}, {
  path: '/v1/agentes/frequencia-dos-agentes',
  name: ROUTE_AGENTS_FREQUENCY_DATE,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Frequência dos Agentes') },
  component: AgentsFrequencyDateReport
}, {
  path: '/v1/agentes/sessoes',
  name: ROUTE_AGENTS_SESSIONS,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Sessões dos Agentes') },
  component: AgentsSessionsReport
}, {
  path: '/v1/agentes/sessoes-de-eventos',
  name: ROUTE_AGENTS_SESSIONS_EVENTS,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Sessões por Eventos') },
  component: AgentsSessionsEventsReport
}, {
  path: '/v1/agentes/detalhado',
  name: ROUTE_AGENTS_DETAILED,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Disponibilidade dos Agentes') },
  component: AgentsDetailedReport
}, {
  path: '/v1/agentes/ociosidade',
  name: ROUTE_AGENTS_IDLE,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Ociosidade dos Agentes') },
  component: AgentsIdleReport
}, {
  path: '/v1/agentes/produtividade',
  name: ROUTE_AGENTS_PRODUCTIVE,
  meta: { tab: 'agents', breadcrumb: reportBreadcrumbs('Produtividade dos Agentes') },
  component: AgentsProductive
}]

const api = window.api

const TRUNKINGS_ERROR = 'TRUNKINGS_ERROR'
const TRUNKINGS_SUCCESS = 'TRUNKINGS_SUCCESS'
const TRUNKINGS_LOADING = 'TRUNKINGS_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading)
}

const sortByName = (a, b) => {
  if (a.name > b.name) return 1
  return a.name < b.name ? -1 : 0
}

const actions = {
  async loadTrunkings ({ commit, getters }, args) {
    if (getters.isLoading) return
    commit(TRUNKINGS_LOADING)

    try {
      const result = await api.get('/trunkings')
      result.data.sort(sortByName)
      commit(TRUNKINGS_SUCCESS, result)
    } catch (err) {
      commit(TRUNKINGS_ERROR, err)
    }
  }
}

const mutations = {
  [TRUNKINGS_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [TRUNKINGS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [TRUNKINGS_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'trunkingStore/TRUNKINGS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('trunkingStore/loadTrunkings', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

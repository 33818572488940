const api = window.api

const EXTENSION_MODELS_ERROR = 'EXTENSION_MODELS_ERROR'
const EXTENSION_MODELS_SUCCESS = 'EXTENSION_MODELS_SUCCESS'
const EXTENSION_MODELS_LOADING = 'EXTENSION_MODELS_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading)
}

const actions = {
  async loadExtensionModels ({ commit, getters }) {
    if (getters.isLoading) return
    commit(EXTENSION_MODELS_LOADING)

    try {
      const result = await api.get('/extension-models')
      commit(EXTENSION_MODELS_SUCCESS, result)
    } catch (err) {
      commit(EXTENSION_MODELS_ERROR, err)
    }
  }
}

const mutations = {
  [EXTENSION_MODELS_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [EXTENSION_MODELS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [EXTENSION_MODELS_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'extensionModelsStore/EXTENSION_MODELS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('extensionModelsStore/loadExtensionModels', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

import {
  ROUTE_CALLS_LIST,
  ROUTE_CALLS_BY_NUMBER,
  ROUTE_CALLS_BY_DURATION,
  ROUTE_CALLS_BY_CARRIER,
  ROUTE_CALLS_BY_LOCALITY,
  ROUTE_CALLS_BY_TRUNKING,
  ROUTE_CALLS_BY_QUEUE,
  ROUTE_CALLS_BY_TIME,
  ROUTE_CALLS_BY_TYPE,
  ROUTE_CALLS_BY_ABANDON,
  ROUTE_CALLS_BY_PERFORMANCE,
  ROUTE_ATTENDANCE_AGENTS,
  ROUTE_ATTENDANCE_IVR
} from './constants'

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Chamadas', active: false }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

const AttendanceAgents = () => import(/* webpackChunkName: "attendance-agents" */ './views/AttendanceAgents')
const CallList = () => import(/* webpackChunkName: "calls-list" */ './views/CallList')
const CallByNumber = () => import(/* webpackChunkName: "calls-by-number" */ './views/CallByNumber')
const CallByDuration = () => import(/* webpackChunkName: "calls-by-duration" */ './views/CallByDuration')
const CallByCarrier = () => import(/* webpackChunkName: "calls-by-carrier" */ './views/CallByCarrier')
const CallByQueue = () => import(/* webpackChunkName: "calls-by-queue" */ './views/CallByQueue')
const CallByTime = () => import(/* webpackChunkName: "calls-by-time" */ './views/CallByTime')
const CallByType = () => import(/* webpackChunkName: "calls-by-type" */ './views/CallByType')
const CallByTrunking = () => import(/* webpackChunkName: "calls-by-trunking" */ './views/CallByTrunking')
const CallByLocality = () => import(/* webpackChunkName: "calls-by-locality" */ './views/CallByLocality')
const CallByAbandon = () => import(/* webpackChunkName: "calls-by-abandon" */ './views/CallByAbandon')
const CallByPerformance = () => import(/* webpackChunkName: "calls-by-performance" */ './views/CallByPerformance')
const CallsIvr = () => import(/* webpackChunkName: "ivr-calls" */ './views/IvrCallsRender')

export default [{
  path: '/v1/chamadas',
  name: ROUTE_CALLS_LIST,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Busca Geral', active: true }) },
  component: CallList
}, {
  path: '/v1/chamadas/por-abandono',
  name: ROUTE_CALLS_BY_ABANDON,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Abandono', active: true }) },
  component: CallByAbandon
},
{
  path: '/v1/chamadas/por-tipo',
  name: ROUTE_CALLS_BY_TYPE,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Tipo', active: true }) },
  component: CallByType
}, {
  path: '/v1/chamadas/por-numero',
  name: ROUTE_CALLS_BY_NUMBER,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Número', active: true }) },
  component: CallByNumber
}, {
  path: '/v1/chamadas/por-localidade',
  name: ROUTE_CALLS_BY_LOCALITY,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Localidade', active: true }) },
  component: CallByLocality
}, {
  path: '/v1/chamadas/por-operadora',
  name: ROUTE_CALLS_BY_CARRIER,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Operadora', active: true }) },
  component: CallByCarrier
}, {
  path: '/v1/chamadas/por-tronco',
  name: ROUTE_CALLS_BY_TRUNKING,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Tronco', active: true }) },
  component: CallByTrunking
}, {
  path: '/v1/chamadas/por-periodo',
  name: ROUTE_CALLS_BY_TIME,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Horário', active: true }) },
  component: CallByTime
}, {
  path: '/v1/chamadas/por-duracao',
  name: ROUTE_CALLS_BY_DURATION,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Duração', active: true }) },
  component: CallByDuration
}, {
  path: '/v1/chamadas/por-fila',
  name: ROUTE_CALLS_BY_QUEUE,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Filas', active: true }) },
  component: CallByQueue
}, {
  path: '/v1/chamadas/por-desempenho',
  name: ROUTE_CALLS_BY_PERFORMANCE,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Chamadas por Desempenho', active: true }) },
  component: CallByPerformance
}, {
  path: '/v1/atendimento/agentes',
  name: ROUTE_ATTENDANCE_AGENTS,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Atendimentos por Horário', active: true }) },
  component: AttendanceAgents
}, {
  path: '/v1/atendimento/uras',
  name: ROUTE_ATTENDANCE_IVR,
  meta: { tab: 'calls', breadcrumb: baseBreadcrumbs({ label: 'Atendimentos da Ura', active: true }) },
  component: CallsIvr
}
]

import VonixJS from '@/utils/VonixJS'
const getCustomerId = window.getCustomerId

export default () => {
  let vonixJS = null
  let listEvents = null
  let requestConn = false
  let configEvents = {}

  const unregister = () => {
    if (!listEvents) return
    Object.values(listEvents).forEach((cancelListener) => cancelListener())
    listEvents = null
  }

  const registerNewListeners = (listeners) => (
    Object.keys(listeners).reduce((acc, onEventVonixJS) => {
      acc[onEventVonixJS] = vonixJS[onEventVonixJS](listeners[onEventVonixJS])
      return acc
    }, {})
  )

  const setInterests = (queues, agents, extensions) => {
    vonixJS && vonixJS.setInterests(queues, agents, extensions)
  }

  const onEvents = (accessToken, interests, listeners) => {
    configEvents = { interests, listeners, accessToken }
    if (requestConn) return
    requestConn = true

    if (!vonixJS) vonixJS = new VonixJS(getCustomerId(), configEvents.accessToken)

    return vonixJS.getConnection().then(() => {
      unregister()
      listEvents = registerNewListeners(configEvents.listeners)
      const { queues, agents, extensions } = configEvents.interests
      setInterests(queues, agents, extensions)
      requestConn = false
    }).catch(async (err) => {
      console.log({ err })
      vonixJS = null
      requestConn = false
      if (err.message !== 'invalid token') setTimeout(() => onEvents(configEvents.accessToken, configEvents.interests, configEvents.listeners), 3000)
    })
  }

  const close = () => {
    unregister()
    vonixJS && vonixJS.close()
    vonixJS = null
  }

  return {
    close,
    onEvents,
    unregister,
    setInterests
  }
}

const api = window.api

const ENTRY_NUMBERS_ERROR = 'ENTRY_NUMBERS_ERROR'
const ENTRY_NUMBERS_SUCCESS = 'ENTRY_NUMBERS_SUCCESS'
const ENTRY_NUMBERS_LOADING = 'ENTRY_NUMBERS_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading)
}

const actions = {
  async loadEntryNumbers ({ commit, getters }) {
    if (getters.isLoading) return
    commit(ENTRY_NUMBERS_LOADING)

    try {
      const result = await api.get('/entry-numbers')
      commit(ENTRY_NUMBERS_SUCCESS, result)
    } catch (err) {
      commit(ENTRY_NUMBERS_ERROR, err)
    }
  }
}

const mutations = {
  [ENTRY_NUMBERS_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [ENTRY_NUMBERS_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [ENTRY_NUMBERS_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'entryNumbersStore/ENTRY_NUMBERS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('entryNumbersStore/loadEntryNumbers', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

import {
  ROUTE_QUEUE_LIST,
  ROUTE_QUEUE_EDIT,
  ROUTE_QUEUE_CREATE
} from './constants'

const QueueForm = () => import(/* webpackChunkName: "queues-form" */ './views/QueueForm')
const QueueList = () => import(/* webpackChunkName: "queues-list" */ './views/QueueList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Filas', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/filas',
  name: ROUTE_QUEUE_LIST,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs(null, true) },
  component: QueueList
}, {
  path: '/v1/filas/adicionar',
  name: ROUTE_QUEUE_CREATE,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs({ label: 'Adicionar' }) },
  component: QueueForm
}, {
  path: '/v1/filas/:id/editar',
  name: ROUTE_QUEUE_EDIT,
  meta: { permission: 'adminQueues', breadcrumb: baseBreadcrumbs({ label: 'Editar #' }) },
  component: QueueForm
}]

import './setPublicPath'
import Vue from 'vue'
import App from '@/App.vue'
import makeStore from '@/store'
import router from '@/router'
import VueImg from 'v-img'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import singleSpaVue from 'single-spa-vue'
import '@/quasar'

Vue.use(VueMask)
Vue.use(VueImg)
Vue.use(Vuelidate)
Vue.config.productionTip = false

const store = makeStore(router)

window.getAuthSession().then((session) => {
  session && store.commit('SET_SESSION', session)
}).catch(() => null)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    store,
    router,
    render: (h) => h(App)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount

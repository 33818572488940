import {
  ROUTE_EMAIL_LIST,
  ROUTE_EMAIL_MESSAGES
} from './constants'

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'Emails', active: false }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

const EmailList = () => import(/* webpackChunkName: "emails-list" */ './views/EmailList')
const EmailMessagesList = () => import(/* webpackChunkName: "emails-messages-list" */ './views/EmailMessagesList')

export default [{
  path: '/v1/emails',
  name: ROUTE_EMAIL_LIST,
  meta: { tab: 'emails', breadcrumb: baseBreadcrumbs({ label: 'Busca Geral', active: true }) },
  component: EmailList
}, {
  path: '/v1/mensagens',
  name: ROUTE_EMAIL_MESSAGES,
  meta: { tab: 'emails', breadcrumb: baseBreadcrumbs({ label: 'Mensagens', active: true }) },
  component: EmailMessagesList
}]

import {
  ROUTE_PAUSE_REASON_LIST,
  ROUTE_PAUSE_REASON_EDIT,
  ROUTE_PAUSE_REASON_CREATE
} from './constants'

const PauseReasonForm = () => import(/* webpackChunkName: "pause-reasons-form" */ './views/PauseReasonForm')
const PauseReasonList = () => import(/* webpackChunkName: "pause-reasons-list" */ './views/PauseReasonList')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Motivos de Pausa', active: !child }
  ]

  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/motivos-de-pausa',
  name: ROUTE_PAUSE_REASON_LIST,
  meta: { permission: 'adminPauseReasons', breadcrumb: baseBreadcrumbs(null, true) },
  component: PauseReasonList
}, {
  path: '/v1/motivos-de-pausa/adicionar',
  name: ROUTE_PAUSE_REASON_CREATE,
  meta: { permission: 'adminPauseReasons', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: PauseReasonForm
}, {
  path: '/v1/motivos-de-pausa/:id/editar',
  name: ROUTE_PAUSE_REASON_EDIT,
  meta: { permission: 'adminPauseReasons', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: PauseReasonForm
}]

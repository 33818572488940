import { ROUTE_FINANCE_LIST } from './constants'

const FinanceList = () => import(/* webpackChunkName: "agents-list" */ './views/FinanceList')

export default [{
  path: '/v1/finances',
  name: ROUTE_FINANCE_LIST,
  meta: { breadcrumb: [{ label: 'Ramais Utilizados', active: true }] },
  component: FinanceList
}]

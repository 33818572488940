const api = window.api

const QUEUES_ERROR = 'QUEUES_ERROR'
const QUEUES_SUCCESS = 'QUEUES_SUCCESS'
const QUEUES_LOADING = 'QUEUES_LOADING'
const MODEL_OPTIONS = 'MODEL_OPTIONS'

const state = {
  rows: [],
  loading: false,
  options: [],
  dialerQueueStatus: ''
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  isOptions: (state) => state.options,
  queuesObj: (state) => state.rows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {}),
  queuesMap: (state) => state.rows.filter(isActive).map(({ id, name, description }) => ({ id, name, description }))
}

const actions = {
  async loadQueues ({ commit, getters }, userQueues) {
    if (getters.isLoading) return
    commit(QUEUES_LOADING)

    try {
      const result = await api.get('/queues', { queryStringParameters: { limit: 9999, userQueues } })
      commit(QUEUES_SUCCESS, result)
    } catch (err) {
      commit(QUEUES_ERROR, err)
    }
  },

  async sendDialerPauseAction ({ commit }, data) {
    commit(QUEUES_LOADING)
    const { queueId } = data
    const body = { ...data }

    try {
      await api
        .post(`/queues/${queueId}/dialer/pause`, { body })
        .catch((err) => ({ error: err.message }))
    } catch (error) {
      commit(QUEUES_ERROR, error)
    }
  },

  async patchQueue (_, queue) {
    const { id } = queue
    try {
      api.patch(`/queues/${id}`, { body: queue })
    } catch (err) {
      console.log(err)
    }
  },

  options ({ commit }, options) {
    commit(MODEL_OPTIONS, options)
  }
}

const mutations = {
  [QUEUES_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [QUEUES_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [QUEUES_ERROR] (_, err) {
    console.log(err)
  },

  [MODEL_OPTIONS] (state, { options }) {
    Object.assign(state, { options })
  }
}

const middlewares = {
  'queuesStore/QUEUES_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('queuesStore/loadQueues', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

const isActive = ({ active }) => (
  ['1', 't', 'true', 'yes', 'y'].includes(String(active).toLowerCase().trim())
)

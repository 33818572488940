const api = window.api

const TRANSCRIPTION_ERROR = 'TRANSCRIPTION_ERROR'
const TRANSCRIPTION_SUCCESS = 'TRANSCRIPTION_SUCCESS'
const TRANSCRIPTION_LOADING = 'TRANSCRIPTION_LOADING'

const state = {
  rows: [],
  loading: false
}

const getters = {
  isLoading: (state) => Boolean(state.loading),
  transcriptionsObj: (state) => state.rows
}

const actions = {
  async loadTranscriptions ({ commit, getters }) {
    if (getters.isLoading) return
    commit(TRANSCRIPTION_LOADING)

    try {
      const result = await api.get('/transcriptions', { queryStringParameters: { limit: 9999 } })
      commit(TRANSCRIPTION_SUCCESS, result)
    } catch (err) {
      commit(TRANSCRIPTION_ERROR, err)
    }
  }
}

const mutations = {
  [TRANSCRIPTION_LOADING] (state) {
    Object.assign(state, { loading: true })
  },

  [TRANSCRIPTION_SUCCESS] (state, { data: rows }) {
    Object.assign(state, {
      rows,
      loading: false
    })
  },

  [TRANSCRIPTION_ERROR] (_, err) {
    console.log(err)
  }
}

const middlewares = {
  'transcriptionsStore/TRANSCRIPTIONS_ERROR': ({ dispatch }) => (
    setTimeout(() => (
      dispatch('transcriptionsStore/loadTranscriptions', { renew: true })
    ), 1500)
  )
}

export default {
  state,
  actions,
  getters,
  mutations,
  middlewares,
  namespaced: true
}

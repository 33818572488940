import {
  ROUTE_TOKEN_LIST,
  ROUTE_TOKEN_EDIT,
  ROUTE_TOKEN_CREATE
} from './constants'

const TokenForm = () => import(/* webpackChunkName: "tokens-form" */ './views/TokenForm')
const TokenList = () => import(/* webpackChunkName: "tokens-list" */ './views/TokenList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Tokens', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/tokens',
  name: ROUTE_TOKEN_LIST,
  meta: { permission: 'adminTokens', breadcrumb: configBreadcrumbs(null, true) },
  component: TokenList
}, {
  path: '/v1/tokens/adicionar',
  name: ROUTE_TOKEN_CREATE,
  meta: { permission: 'adminTokens', breadcrumb: configBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: TokenForm
}, {
  path: '/v1/tokens/:id/editar',
  name: ROUTE_TOKEN_EDIT,
  meta: { permission: 'adminTokens', breadcrumb: configBreadcrumbs({ label: 'Editar #', active: true }) },
  component: TokenForm
}]

import {
  ROUTE_PROFILE_LIST,
  ROUTE_PROFILE_EDIT,
  ROUTE_PROFILE_CREATE
} from './constants'

const ProfileForm = () => import(/* webpackChunkName: "profilers-form" */ './views/ProfileForm')
const ProfileList = () => import(/* webpackChunkName: "profilers-list" */ './views/ProfileList')

const baseBreadcrumbs = (child, active) => {
  const breadcrumbs = [
    { label: 'Configurações', active: false },
    { label: 'Rotas de Saída', active }
  ]

  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/v1/rotas-de-saida',
  name: ROUTE_PROFILE_LIST,
  meta: { permission: 'adminRoutes', breadcrumb: baseBreadcrumbs(null, true) },
  component: ProfileList
}, {
  path: '/v1/rotas-de-saida/adicionar',
  name: ROUTE_PROFILE_CREATE,
  meta: { permission: 'adminRoutes', breadcrumb: baseBreadcrumbs({ label: 'Adicionar', active: true }) },
  component: ProfileForm
}, {
  path: '/v1/rotas-de-saida/:id/editar',
  name: ROUTE_PROFILE_EDIT,
  meta: { permission: 'adminRoutes', breadcrumb: baseBreadcrumbs({ label: 'Editar #', active: true }) },
  component: ProfileForm
}]
